import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { SideNav } from './dashboard/side-nav';
import { TopNav } from './dashboard/top-nav';
import { Routes, Route, Outlet, useLocation} from 'react-router-dom'

import Login from './pages/login'
import ForgotPassword from './pages/ForgotPassword';
import AddSeller from './pages/AddSeller'
import CreateSellerQuestionnaire from './pages/CreateSellerQuestionnaire'
import Businesses from './pages/Businesses'
import Business from './pages/Business'
import Contracts from './pages/Contracts'
import CashFlowAnalysis from './pages/ContractsCashFlowAnalysis'
import ConsentOfSpouse from './pages/ContractsConsentOfSpouse'
import CorporateResolutionToSell from './pages/ContractsCorporateResolutionToSell'
import BrokerConfidentiality from './pages/ContractsBrokerConfidentiality'

import Settings from './pages/Settings'
import NotFound from './pages/404'
import SellerQuestionnaires from './pages/SellerQuestionnaires'
import SellerQuestionnaire from './pages/SellerQuestionnaire'

export default function App() {

  const Wrapper = () => {

    // const { children } = props;
    const location = useLocation();
    const [openNav, setOpenNav] = useState(true);

    useEffect(
      () => {
        if (openNav) {
          setOpenNav(true);
        }
      },
      [location, openNav]
    );

    const SIDE_NAV_WIDTH = 280;

    const LayoutRoot = styled('div')(({ theme }) => ({
      display: 'flex',
      flex: '1 1 auto',
      maxWidth: '100%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: SIDE_NAV_WIDTH
      }
    }));

    const LayoutContainer = styled('div')({
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%'
    });

    return(
      <>
        <TopNav onNavOpen={() => setOpenNav(true)} />
        <SideNav onClose={() => setOpenNav(false)} open={openNav}/>
        <LayoutRoot>
          <LayoutContainer>
            <Outlet />      
          </LayoutContainer>
        </LayoutRoot>
      </>
    )
  }


  return(
    <Routes>
      <Route exact path="/login" element={<Login/>}/>
      <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path='*' element={<NotFound />} />

      <Route element={<Wrapper/>}>
        <Route exact path="/businesses" element={<Businesses/>}/>
        <Route exact path="/businesses/:businessId" element={<Business/>}/>
        <Route exact path="/add-seller" element={<AddSeller/>}/>
        <Route exact path="/contracts" element={<Contracts/>}/>
        <Route exact path="/contracts/cash-flow-analysis" element={<CashFlowAnalysis/>}/>
        <Route exact path="/contracts/consent-of-spouse" element={<ConsentOfSpouse/>}/>
        <Route exact path="/contracts/corporate-resolution-to-sell" element={<CorporateResolutionToSell/>}/>
        <Route exact path="/contracts/broker-confidentiality" element={<BrokerConfidentiality/>}/>

        <Route exact path="/seller-questionnaires" element={<SellerQuestionnaires/>}/>
        <Route exact path="/questionnaires/:questionnaireId" element={<SellerQuestionnaire/>}/>
        <Route exact path="/create-seller-questionnaire" element={<CreateSellerQuestionnaire/>}/>
        <Route exact path="/settings" element={<Settings/>}/>
      </Route>

    </Routes>
  )
}
