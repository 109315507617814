import { useCallback, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/auth-context';
import { Helmet } from "react-helmet";

import { 
  Box, 
  Container, 
  Stack, 
  Unstable_Grid2 as Grid,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';

import {
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore'
import {db} from '../Firebase/Firebase';

import { 
  getAuth, 
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth'

export default function Settings(){

  const { user } = useContext(AuthContext);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    newPassword: '',
    emailPassword: '',
  })

  useEffect(() => {
    //  Getting business data
    const getData = async () => { 
        const docRef = doc(db, "admins", user.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setValues({
            firstName: docSnap.data().firstName,
            lastName: docSnap.data().lastName,
            email: docSnap.data().email,
            phone: docSnap.data().phone,
          })
        }
    }
    getData()
},[])

  const handleChange = useCallback(
    (event) => {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    },
    []
  )

  async function handleSubmitProfileInfo(event){
    event.preventDefault()
    await updateDoc(doc(db, "admins", user.id), {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone
    })
    .then(() => {
      //  Display success message
      setSuccessMessageOpen(true)
      setTimeout(() => setSuccessMessageOpen(false), 4000);
    })
    .catch(() => {
      //  Display error message
      setErrorMessageOpen(true)
      setTimeout(() => setErrorMessageOpen(false), 4000);
    })
  }

  async function handleSubmitPassword(event){
    event.preventDefault();
     /////   Reauthenticating    /////
     const credential = EmailAuthProvider.credential(getAuth().currentUser.email, values.password)

     const result = await reauthenticateWithCredential(getAuth().currentUser, credential)
     .catch((error) => {
        //  Display error message
        setErrorMessageOpen(true)
        setTimeout(() => setErrorMessageOpen(false), 4000);
        return
     })
 
     if(result){
       /////   Updating password   /////
       updatePassword(getAuth().currentUser, values.newPassword)
       .then(() => {
          //  Display success message
          setSuccessMessageOpen(true)
          setTimeout(() => setSuccessMessageOpen(false), 4000);
          setValues({
            password: '',
            newPassword: ''
          })
        })
        .catch((error) => {
          //  Display error message
          setErrorMessageOpen(true)
          setTimeout(() => setErrorMessageOpen(false), 4000);
        })
     }
  }

  async function handleSubmitEmail(event){
    event.preventDefault();
    /////   Reauthenticating    /////
    const credential = EmailAuthProvider.credential(getAuth().currentUser.email, values.emailPassword)
    
    const result = await reauthenticateWithCredential(getAuth().currentUser, credential)
    .catch(() => {
      //  Display error message
      setErrorMessageOpen(true)
      setTimeout(() => setErrorMessageOpen(false), 4000);
      return
    })

    if(result){
      /////   Updating email in firebase auth   /////
      await updateEmail(getAuth().currentUser, values.email)
      .then(async () => {
        await updateDoc(doc(db, "admins", user.id), {
          email: values.email
        })
        .then(() => {
          //  Display success message
          setSuccessMessageOpen(true)
          setTimeout(() => setSuccessMessageOpen(false), 4000);
          setValues({
            emailPassword: ''
          })
        })
      })
      .catch((error) => {
        //  Display error message
        setErrorMessageOpen(true)
        setTimeout(() => setErrorMessageOpen(false), 4000);
      })

    }
  }


  return(
  <>
    <Helmet>
      <title>
        Settings | The CBA Group
      </title>
    </Helmet>
    <Box component="main" sx={{flexGrow: 1}}>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              Settings
            </Typography>
          </div>
          <div>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <form autoComplete="off" noValidate onSubmit={handleSubmitProfileInfo}>
                  <Card>
                    <CardHeader subheader="The information can be edited" title="Profile"/>
                    <CardContent sx={{ pt: 0 }}>
                      <Box sx={{ m: -1.5 }}>
                        <Grid container spacing={3}>
                          <Grid xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="First name"
                              name="firstName"
                              onChange={handleChange}
                              required
                              value={values.firstName}
                            />
                          </Grid>
                          <Grid xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="Last name"
                              name="lastName"
                              onChange={handleChange}
                              required
                              value={values.lastName}
                            />
                          </Grid>
                          <Grid xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="Phone Number"
                              name="phone"
                              onChange={handleChange}
                              type="number"
                              value={values.phone}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      <Button type='submit' variant="contained">
                        Update
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmitPassword}>
                <Card>
                  <CardHeader subheader="Update password" title="Password"/>
                  <Divider />
                  <CardContent>
                    <Stack spacing={3} sx={{ maxWidth: 400 }}>
                      <TextField
                        fullWidth
                        label="Current Password"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                      />
                      <TextField
                        fullWidth
                        label="New Password"
                        name="newPassword"
                        onChange={handleChange}
                        type="password"
                        value={values.newPassword}
                      />
                    </Stack>
                  </CardContent>
                  <Divider />
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button type='submit' variant="contained">
                      Update
                    </Button>
                  </CardActions>
                </Card>
              </form>
            </Grid>
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmitEmail}>
                <Card>
                  <CardHeader subheader="Update email" title="Email"/>
                  <Divider />
                  <CardContent>
                    <Stack spacing={3} sx={{ maxWidth: 400 }}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                      />
                      <TextField
                        fullWidth
                        label="Password"
                        name="emailPassword"
                        onChange={handleChange}
                        type="password"
                        value={values.emailPassword}
                      />
                    </Stack>
                  </CardContent>
                  <Divider />
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button type='submit' variant="contained">
                      Update
                    </Button>
                  </CardActions>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={() => setSuccessMessageOpen(false)}>
          <Alert onClose={() => setSuccessMessageOpen(false)} severity="success" sx={{ width: '100%' }}>
            Info updated!
          </Alert>
      </Snackbar>
      <Snackbar open={errorMessageOpen} autoHideDuration={6000} onClose={() => setErrorMessageOpen(false)}>
          <Alert onClose={() => setErrorMessageOpen(false)} severity="error" sx={{ width: '100%' }}>
            An error occured. Please try again
          </Alert>
      </Snackbar>
    </Box>
  </>
  )
}
