import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  Snackbar,
  Alert
} from '@mui/material';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
    collection, 
    query, 
    getDocs,
    addDoc,
    getDoc,
    doc,
    setDoc
} from 'firebase/firestore'
  
import {db} from '../Firebase/Firebase';

export default function AddSeller() {

    const [successMessageOpen, setSuccessMessageOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessageOpen, setErrorMessageOpen] = useState(false)
    const [selectedSellerQuestionnaire, setSelectedSellerQuestionnaire] = useState('Select');
    const [sellerQuestionnaires, setSellerQuestionnaires] = useState([]);
    const [password, setPassword] = useState('');
    const [sellerValues, setSellerValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const [businessValues, setBusinessValues] = useState({
        address: '',
        description: '',
        name: '',
        yearsInBusiness: 0,
        numberOfEmployees: 0,
        totalSales: 0,
        priceInMind: 0,
        priceInMindDetermination: '',
        inventoryValue: 0,
        totalCustomers: 0,
        leaseCost: 0,
        buildingValue: 0,
        website: '',
        considerSelling: "No",
        consideredGettingEvaluation: "No",
        carryInventory: "No",
        isManager: "No",
        isBusinessProfitable: "No",
        corporationType: "LLC",
        ownOrLeaseBuilding: "Lease",
        isLeaseAssignable: "No",
        dateCreated: ''
    });
    
    useEffect(() => {
        const getQuestionnaires = async () => { 
            const sellerQuestionnaires = []
            const q = query(collection(db, "sellerQuestionnaires"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const questionnaire = {
                    label:doc.data().firstName + ' ' + doc.data().lastName + '; ' + doc.data().businessName,
                    value: doc.id
                }
                sellerQuestionnaires.push(questionnaire)
            });
            // setSelectedSellerQuestionnaire(sellerQuestionnaires[0].value)
            const initialQuestionnaire = {
                label: 'Select',
                value: 'Select',
            }
            sellerQuestionnaires.push(initialQuestionnaire)
            setSellerQuestionnaires(sellerQuestionnaires)
          }
        getQuestionnaires()
    },[])

    const handleSubmit = async (event) => {

        event.preventDefault();
        await createUserWithEmailAndPassword(getAuth(), sellerValues.email, password)
        .then( async (userCredential) => {
            const user = userCredential.user

            // Add a new business document
            await addDoc(collection(db, "businesses"), {
                businessName: businessValues.name,
                businessDescription: businessValues.description,
                address: businessValues.address,
                sellerId: userCredential.user.uid,
                yearsInBusiness: businessValues.yearsInBusiness,
                numberOfEmployees: businessValues.numberOfEmployees,
                totalSales: businessValues.totalSales,
                priceInMind: businessValues.priceInMind,
                priceInMindDetermination: businessValues.priceInMindDetermination,
                inventoryValue: businessValues.inventoryValue,
                totalCustomers: businessValues.totalCustomers,
                leaseCost: businessValues.leaseCost,
                buildingValue: businessValues.buildingValue,
                website: businessValues.website,
                considerSelling: businessValues.considerSelling,
                consideredGettingEvaluation: businessValues.consideredGettingEvaluation,
                carryInventory: businessValues.carryInventory,
                isManager: businessValues.isManager,
                isBusinessProfitable: businessValues.isBusinessProfitable,
                corporationType: businessValues.corporationType,
                ownOrLeaseBuilding: businessValues.ownOrLeaseBuilding,
                isLeaseAssignable: businessValues.isLeaseAssignable,
                dateCreated: businessValues.dateCreated,
                uploadedDocumentTypes: []
            })

            // Add a new seller document
            await setDoc(doc(db, "sellers", userCredential.user.uid), {
                firstName: sellerValues.firstName,
                lastName: sellerValues.lastName,
                email: sellerValues.email,
                phone: sellerValues.phone,
                uploadedDocumentTypes: []
            })
            .then(() => {
                //  Clear all inputs
                setSellerValues({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                })

                setBusinessValues({
                    address: '',
                    description: '',
                    name: '',
                    yearsInBusiness: 0,
                    numberOfEmployees: 0,
                    totalSales: 0,
                    priceInMind: 0,
                    priceInMindDetermination: '',
                    inventoryValue: 0,
                    totalCustomers: 0,
                    leaseCost: 0,
                    buildingValue: 0,
                    website: '',
                    considerSelling: "No",
                    consideredGettingEvaluation: "No",
                    carryInventory: "No",
                    isManager: "No",
                    isBusinessProfitable: "No",
                    corporationType: "LLC",
                    ownOrLeaseBuilding: "Lease",
                    isLeaseAssignable: "No",
                    dateCreated: ''
                });

                setSelectedSellerQuestionnaire('')
                setPassword('')
                setSellerQuestionnaires([])

                //  Display success message
                setSuccessMessageOpen(true)
                setTimeout(() => setSuccessMessageOpen(false), 4000);
            })
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            //  Display error message
            setErrorMessage(errorMessage)
            setErrorMessageOpen(true)
            setTimeout(() => setErrorMessageOpen(false), 4000);
        });
    }

    async function changeQuestionnaire(event){
        setSelectedSellerQuestionnaire(event.target.value)

        const docRef = doc(db, "sellerQuestionnaires", event.target.value);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log('setting seller phone to: ' + docSnap.data().phone)
            setSellerValues({
                firstName: docSnap.data().firstName,
                lastName: docSnap.data().lastName,
                email: docSnap.data().email,
                phone: docSnap.data().phone,
            })
            setBusinessValues({
                address: docSnap.data().businessAddress,
                description: docSnap.data().businessDescription,
                name: docSnap.data().businessName,
                yearsInBusiness: docSnap.data().yearsInBusiness,
                numberOfEmployees: docSnap.data().numberOfEmployees,
                totalSales: docSnap.data().totalSales,
                priceInMind: docSnap.data().priceInMind,
                priceInMindDetermination: docSnap.data().priceInMindDetermination,
                inventoryValue: docSnap.data().inventoryValue,
                totalCustomers: docSnap.data().totalCustomers,
                leaseCost: docSnap.data().leaseCost,
                buildingValue: docSnap.data().buildingValue,
                website: docSnap.data().website,
                considerSelling: docSnap.data().considerSelling,
                consideredGettingEvaluation: docSnap.data().consideredGettingEvaluation,
                carryInventory: docSnap.data().carryInventory,
                isManager: docSnap.data().isManager,
                isBusinessProfitable: docSnap.data().isBusinessProfitable,
                corporationType: docSnap.data().corporationType,
                ownOrLeaseBuilding: docSnap.data().ownOrLeaseBuilding,
                isLeaseAssignable: docSnap.data().isLeaseAssignable,
                dateCreated: docSnap.data().dateCreated
            })
        }

    }

    return (
    <>
        <Helmet>
            <title>
                Add a Seller | The CBA Group
            </title>
        </Helmet>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card>
                <Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={() => successMessageOpen(false)}>
                    <Alert onClose={() => successMessageOpen(false)} severity="success" sx={{ width: '100%' }}>
                        Seller Account Created!
                    </Alert>
                </Snackbar>
                <Snackbar open={errorMessageOpen} autoHideDuration={6000} onClose={() => successMessageOpen(false)}>
                    <Alert onClose={() => successMessageOpen(false)} severity="error" sx={{ width: '100%' }}>
                        Error Creating Account: {errorMessage}
                    </Alert>
                </Snackbar>

                <CardHeader subheader="Select the seller questionnaire for this seller" title="Seller Questionnaire"/>
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid container spacing={3} >
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Select Questionnaire"
                                    name="questionnaire"
                                    onChange={(event) => changeQuestionnaire(event)}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={selectedSellerQuestionnaire}
                                >
                                    {sellerQuestionnaires.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>

                <CardHeader subheader="Information about the seller" title="Seller"/>
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid container spacing={3} >
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="First name"
                                    name="firstName"
                                    required
                                    value={sellerValues.firstName}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Last name"
                                name="lastName"
                                required
                                value={sellerValues.lastName}
                            />
                            </Grid>
                            <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Email Address"
                                name="email"
                                required
                                value={sellerValues.email}
                            />
                            </Grid>
                            <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                name="phone"
                                type="text"
                                value={sellerValues.phone}
                            />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>

                <CardHeader subheader="Information used by the seller to login" title="Login Info"/>
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid container spacing={3} >
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    required
                                    value={sellerValues.email}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                    value={password}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>


                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button type='submit' variant="contained">
                    Add Seller
                    </Button>
                </CardActions>
            </Card>
        </form>
    </>

    );
}