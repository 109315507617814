import { useCallback, useMemo, useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Stack, 
  SvgIcon, 
  Typography,
  Card,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';

import { Helmet } from "react-helmet";
import { AuthContext } from '../contexts/auth-context';
import { useContext } from 'react';
import { useSelection } from '../hooks/use-selection';
import { BusinessesTable } from './BusinessesTable';
import { applyPagination } from '../utils/apply-pagination';

import {
  collection, 
  query, 
  getDocs
} from 'firebase/firestore'

import {db} from '../Firebase/Firebase';


export default function Documents() {

  const [searchWord, setSearchWord] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [allBusinesses, setAllBusinesses] = useState([]);

  
  const useDocuments = (page, rowsPerPage) => {
    return useMemo(() => {
      return applyPagination(businesses, page, rowsPerPage);
    }, [businesses, page, rowsPerPage]);
};
  
  const useDocumentIds = (businessDocs) => {
    return useMemo(
      () => {
        return businessDocs.map((businessDocs) => businessDocs.id);
      },
      [businessDocs]
    );
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const businessDocs = useDocuments(page, rowsPerPage);
  const businessesIds = useDocumentIds(businessDocs);
  const businessesSelection = useSelection(businessesIds);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const getBusinesses = async () => { 
        const businesses = []
        const q = query(collection(db, "businesses"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const business = {
                businessName: doc.data().businessName,
                businessDescription: doc.data().businessDescription,
                address: doc.data().address,
                id: doc.id
            }
            businesses.push(business)
        });
        setBusinesses(businesses)
        setAllBusinesses(businesses)
      }
    getBusinesses()
},[]);

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  )

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  )

  function changeSearchword(event){
    setSearchWord(event.target.value)
    if(event.target.value.length > 0){
        const tempBusinesses = []
        for(let i=0; i<allBusinesses.length; i++){
            if(allBusinesses[i].address.toLowerCase().includes(event.target.value.toLowerCase()) ||
            allBusinesses[i].businessName.toLowerCase().includes(event.target.value.toLowerCase()) ||
            allBusinesses[i].businessDescription.toLowerCase().includes(event.target.value.toLowerCase())
            ){
              tempBusinesses.push(allBusinesses[i])
            }
        }
        setBusinesses(tempBusinesses)
    }
    else{
      setBusinesses(allBusinesses)
    }


  }

  return (
    <>
      <Helmet>
        <title>
          Businesses | The CBA Group
        </title>
      </Helmet>

      <Box sx={{flexGrow: 1}}>
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Typography variant="h4">
              Businesses
            </Typography>
            <Card sx={{ p: 2 }}>
                <OutlinedInput sx={{ maxWidth: 500 }} fullWidth placeholder="Search businesses"
                    startAdornment={(
                        <InputAdornment position="start">
                        <SvgIcon
                            color="action"
                            fontSize="small"
                        >
                            <MagnifyingGlassIcon />
                        </SvgIcon>
                        </InputAdornment>
                    )}
                    onChange={(event) => changeSearchword(event)}
                    value={searchWord}
                />
            </Card>
            <BusinessesTable
              count={businesses.length}
              items={businessDocs}
              onDeselectAll={businessesSelection.handleDeselectAll}
              onDeselectOne={businessesSelection.handleDeselectOne}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              onSelectAll={businessesSelection.handleSelectAll}
              onSelectOne={businessesSelection.handleSelectOne}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={businessesSelection.selected}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};