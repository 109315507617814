import { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

import {
  Box,
  Button,
  Unstable_Grid2 as Grid,
  Stack,
  Container,
  SvgIcon, 
  Typography,
  Card, 
  InputAdornment, 
  OutlinedInput, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar
} from '@mui/material';

import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useSelection } from '../hooks/use-selection';
import { SellerQuestionnairesTable } from './SellerQuestionnairesTable';
import { applyPagination } from '../utils/apply-pagination';

import {
  collection, 
  query, 
  getDocs,
  doc,
  deleteDoc,
  onSnapshot
} from 'firebase/firestore'
import {db} from '../Firebase/Firebase';


export default function SellerQuestionnaires() {

    const now = new Date();
    const navigate = useNavigate()

    const [searchWord, setSearchWord] = useState('');
    const [sellerQuestionnaires, setSellerQuestionnaires] = useState([]);
    const [allSellerQuestionnaires, setAllSellerQuestionnaires] = useState([]);
    const [questionnaireDeleted, setQuestionnaireDeleted] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteQuestionnaireId, setDeleteQuestionnaireId] = useState('');

    const useDocuments = (page, rowsPerPage) => {
        return useMemo(() => {
          return applyPagination(sellerQuestionnaires, page, rowsPerPage);
        }, [sellerQuestionnaires, page, rowsPerPage]);
    };

    const useDocumentIds = (questionnaires) => {
        return useMemo(
          () => {
            return questionnaires.map((customer) => customer.id);
          },
          [questionnaires]
        );
    }

    const handlePageChange = useCallback(
        (event, value) => {
          setPage(value);
        },
        []
    )
    
    const handleRowsPerPageChange = useCallback(
        (event) => {
          setRowsPerPage(event.target.value);
        },
        []
    )

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const questionnaires = useDocuments(page, rowsPerPage);
    const questionnairesIds = useDocumentIds(questionnaires);
    const questionnairesSelection = useSelection(questionnairesIds);

    useEffect(() => {


        const questionnaireQuery = query(collection(db, "sellerQuestionnaires"))
        const unsubscribe = onSnapshot(questionnaireQuery, (querySnapshot) => {
            const sellerQuestionnaires = []
            querySnapshot.forEach((doc) => {
                const questionnaire = {
                    name: doc.data().firstName + ' ' + doc.data().lastName,
                    businessName: doc.data().businessName,
                    address: doc.data().businessAddress,
                    dateCreated: doc.data().dateCreated,
                    id: doc.id
                }
                sellerQuestionnaires.push(questionnaire)
            });
            setSellerQuestionnaires(sellerQuestionnaires)
            setAllSellerQuestionnaires(sellerQuestionnaires)
        });


        /* const getQuestionnaires = async () => { 
            const sellerQuestionnaires = []
            const q = query(collection(db, "sellerQuestionnaires"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const questionnaire = {
                    name: doc.data().firstName + ' ' + doc.data().lastName,
                    businessName: doc.data().businessName,
                    address: doc.data().businessAddress,
                    dateCreated: doc.data().dateCreated,
                    id: doc.id
                }
                sellerQuestionnaires.push(questionnaire)
            });
            setSellerQuestionnaires(sellerQuestionnaires)
            setAllSellerQuestionnaires(sellerQuestionnaires)
          }
        getQuestionnaires() */
    },[]);

    function changeSearchword(event){
        setSearchWord(event.target.value)
        if(event.target.value.length > 0){
            const tempSellerQuestionnaires = []
            for(let i=0; i<allSellerQuestionnaires.length; i++){
                if(allSellerQuestionnaires[i].name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                allSellerQuestionnaires[i].businessName.toLowerCase().includes(event.target.value.toLowerCase()) ||
                allSellerQuestionnaires[i].address.toLowerCase().includes(event.target.value.toLowerCase()) ||
                allSellerQuestionnaires[i].dateCreated.toLowerCase().includes(event.target.value.toLowerCase())
                ){
                    tempSellerQuestionnaires.push(allSellerQuestionnaires[i])
                }
            }
            setSellerQuestionnaires(tempSellerQuestionnaires)
        }
        else{
            setSellerQuestionnaires(allSellerQuestionnaires)
        }


    }

    async function deleteQuestionnaire(){
        await deleteDoc(doc(db, "sellerQuestionnaires", deleteQuestionnaireId))
        .then(() => {
            setOpen(false)
            setQuestionnaireDeleted(true)
            setTimeout(() => setQuestionnaireDeleted(false), 6000)
        })
    }

    return (
    <>
        <Helmet>
            <title>
                Questionnaires | The CBA Group
            </title>
        </Helmet>
        <Box>
            <Grid item xs={12} md={8}>
                <Box sx={{flexGrow: 1}}>
                    <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack direction="row" justifyContent="space-between" spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant="h4">
                                Questionnaires
                            </Typography>
                        </Stack>
                        <div>
                            <Button variant="contained" onClick={() => navigate("/create-seller-questionnaire", { replace: true })}
                                startIcon={(
                                    <SvgIcon fontSize="small">
                                    <PlusIcon />
                                    </SvgIcon>
                                )}
                            >
                                Add
                            </Button>
                        </div>
                        </Stack>
                        <Card sx={{ p: 2 }}>
                            <OutlinedInput sx={{ maxWidth: 500 }} fullWidth placeholder="Search"
                                startAdornment={(
                                    <InputAdornment position="start">
                                    <SvgIcon color="action" fontSize="small">
                                        <MagnifyingGlassIcon />
                                    </SvgIcon>
                                    </InputAdornment>
                                )}
                                onChange={(event) => changeSearchword(event)}
                                value={searchWord}
                            />
                        </Card>
                        <SellerQuestionnairesTable
                            count={sellerQuestionnaires.length}
                            items={questionnaires}
                            onDeselectAll={questionnairesSelection.handleDeselectAll}
                            onDeselectOne={questionnairesSelection.handleDeselectOne}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelectAll={questionnairesSelection.handleSelectAll}
                            onSelectOne={questionnairesSelection.handleSelectOne}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={questionnairesSelection.selected}
                            setOpen={(bool) => setOpen(bool)}
                            setDeleteQuestionnaireId={(id) => setDeleteQuestionnaireId(id)}
                        />
                    </Stack>
                    </Container>
                </Box>
            </Grid>
        </Box>

        <Snackbar open={questionnaireDeleted} autoHideDuration={6000} onClose={() => setQuestionnaireDeleted(false)}>
            <Alert onClose={() => setQuestionnaireDeleted(false)} severity="success" sx={{ width: '100%' }}>
                Questionnaire Deleted!
            </Alert>
        </Snackbar>

        <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {"Delete Questionnaire?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this questionnaire?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => deleteQuestionnaire()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
}