import React, { useCallback, useState } from "react";
import html2pdf from "html2pdf.js"
import cbaLogo2 from './CBALogo2.png';
import { Helmet } from "react-helmet";

import { 
  Grid,
  Button,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function ContractsConsentOfSpouse() {

  const [values, setValues] = useState({
    consentOfSpousePersonName: '',
    consentOfSpouseSpouseName: '',
    consentOfSpouseBusinessName: '',
    consentOfSpouseBusinessLocation: ''
  });


  const [consentOfSpouseDay, setConsentOfSpouseDay] = useState('01');
  const [consentOfSpouseMonth, setConsentOfSpouseMonth] = useState('January');
  const [consentOfSpouseYear, setConsentOfSpouseYear] = useState('2023');
  const [consentOfSpouseError, setConsentOfSpouseError] = useState('');

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

  const handleChange = useCallback(
  (event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, [])

  function generateConsentOfSpouse(){

    if(values.consentOfSpouseBusinessLocation === ""){
      setConsentOfSpouseError("Enter a business address")
      setTimeout(() => setConsentOfSpouseError(''), 6000)
      return
    }
    else if(values.consentOfSpouseBusinessName === ""){
      setConsentOfSpouseError("Enter a business name")
      setTimeout(() => setConsentOfSpouseError(''), 6000)
      return
    }
    else if(values.consentOfSpousePersonName === ""){
      setConsentOfSpouseError("Enter a person name")
      setTimeout(() => setConsentOfSpouseError(''), 6000)
      return
    }
    else if(values.consentOfSpouseSpouseName === ""){
      setConsentOfSpouseError("Enter a spouse name")
      setTimeout(() => setConsentOfSpouseError(''), 6000)
      return
    }
    else{
      setConsentOfSpouseError("")
    }
     
    var element = document.getElementById('consentOfSpouseContent');

    html2pdf(element, {
      filename: 'Consent Of Spouse',
      html2canvas: { scale: 2,  logging: true },
      }).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'), '_blank');
    })
  }

  return (
    <>
      <Helmet>
        <title>
          Consent of Spouse
        </title>
      </Helmet>
      
      <Grid container spacing={1} pl={2}>
        <Grid item xs={4}>
          <Typography variant="h4">
            Consent of Spouse
          </Typography>
          <Grid pt={4} pb={4} container spacing={1} >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  value={consentOfSpouseMonth}
                  label="Month"
                  onChange={(e) => setConsentOfSpouseMonth(e.target.value)}
                >
                  {months.map((month) => {
                    return <MenuItem key={month} value={month}>{month}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Day</InputLabel>
                <Select
                  value={consentOfSpouseDay}
                  label="Day"
                  onChange={(e) => setConsentOfSpouseDay(e.target.value)}
                >
                  {days.map((day) => {
                    return <MenuItem key={day} value={day}>{day}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Year</InputLabel>
                <Select
                  value={consentOfSpouseYear}
                  label="Year"
                  onChange={(e) => setConsentOfSpouseYear(e.target.value)}
                >
                  <MenuItem value={'2026'}>{'2026'}</MenuItem>
                  <MenuItem value={'2025'}>{'2025'}</MenuItem>
                  <MenuItem value={'2024'}>{'2024'}</MenuItem>
                  <MenuItem value={'2023'}>{'2023'}</MenuItem>
                  <MenuItem value={'2022'}>{'2022'}</MenuItem>
                  <MenuItem value={'2021'}>{'2021'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Person Name"
                name="consentOfSpousePersonName"
                onChange={handleChange}
                value={values.consentOfSpousePersonName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business Name"
                name="consentOfSpouseBusinessName"
                onChange={handleChange}
                value={values.consentOfSpouseBusinessName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Spouse Name"
                name="consentOfSpouseSpouseName"
                onChange={handleChange}
                value={values.consentOfSpouseSpouseName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business Address"
                name="consentOfSpouseBusinessLocation"
                onChange={handleChange}
                value={values.consentOfSpouseBusinessLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                fullWidth
                sx={{marginTop: '15px', borderRadius: '5px', backgroundColor: 'green', color: 'white'}} 
                onClick={() => generateConsentOfSpouse()}
              >
                Generate PDF
              </Button>
            </Grid>
            <Grid item xs={12}>
              <p style={{fontSize: 25, color: 'red', textAlign: 'center'}}>{consentOfSpouseError}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <div id="consentOfSpouseContent" style={{width: '768px'}}>
            <img style={{height: 100, width: 210, marginLeft: 300, marginTop: 30}} src={cbaLogo2}></img>

            <p style={{textAlign: 'center', marginLeft: 150, width: 500, marginTop: 20, fontSize: 14, fontWeight: 'bold'}}>
              The CBA Group, LLC 20462 Chartwell Center Dr. Suite C, Cornelius, NC 28031 Phone: (704) 895-4274 Fax: (704) 895-4278
            </p>

            <p style={{width: 300, marginLeft: 300, fontSize: 25, fontWeight: 'bold'}}>
              Consent Of Spouse
            </p>

            <p style={{fontSize: 16, width: 625, marginLeft: 75, textAlign: 'center', marginTop: 20}}>
              Whereas I, <strong>{values.consentOfSpousePersonName}</strong> am the spouse of 
              <strong> {values.consentOfSpouseSpouseName}</strong> and do hereby consent and 
              agree to the following: My spouse may sign any and all documents necessary to close 
              the business transaction involving the sale of the Business known as, 
              <strong> {values.consentOfSpouseBusinessName}</strong> located at: 
              <strong> {values.consentOfSpouseBusinessLocation}</strong> which may include real 
              property, if any, associated with the Business.
            </p>

            <p style={{fontSize: 16, width: 500, marginLeft: 50, marginTop: 80}}>
              Dated the <strong>{consentOfSpouseDay}</strong> day of 
              <strong> {consentOfSpouseMonth}</strong>, 
              <strong> {consentOfSpouseYear}</strong>
            </p>


            <p style={{fontSize: 18, marginLeft: 50, textDecoration: "underline", marginTop: 15, marginBottom: 0, padding: 0}}>
              {values.consentOfSpousePersonName}
            </p>

            <div style={{marginLeft: 50, display: "flex"}}>
              <div style={{width: 300}}>
                <p style={{fontSize: 18, width: 300}}>
                  Printed Name
                </p>
              </div>

              <div style={{width: 300, marginLeft: '5%', borderTop: "1px solid black"}}>
                <p style={{width: 300, fontSize: 18}}>
                  <em>
                    Signature
                  </em>
                </p>
              </div>
            </div>

            <p style={{fontSize: 12, width: 150, marginLeft: 575, marginTop: 430}}>CBA Form 102119</p>

          </div>
        </Grid>
      </Grid>
    </>
  )
}

