import * as firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBB6VpKwYDKLf6Lcc2SYxvfTL_0tEplh1Y",
  authDomain: "cba-portal.firebaseapp.com",
  projectId: "cba-portal",
  storageBucket: "cba-portal.appspot.com",
  messagingSenderId: "316147542905",
  appId: "1:316147542905:web:0158d147eece497cc15da8",
  measurementId: "G-C3NWSE9LV0"
};

// export const app = firebase.initializeApp(firebaseConfig);

const app = initializeApp(firebaseConfig);

// Export Firebase
export const db = getFirestore(app);

