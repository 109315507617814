import { useCallback, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { useAuth } from '../hooks/use-auth';

export default function ForgotPassword() {
  const navigate = useNavigate()
  const auth = useAuth()

  const [loadingSendEmail, setLoadingSendEmail] = useState(false)
  const [emailSent, setEmailSent] = useState(false)


  const formik = useFormik({
    initialValues: {
      email: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),
    onSubmit: async (values, helpers) => {
      setLoadingSendEmail(true)
      try {
        await auth.resetPassword(values.email)
        setEmailSent(true)
        setLoadingSendEmail(false)
        formik.setFieldValue('email', '')
        formik.setFieldTouched('email', false)
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoadingSendEmail(false)
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>
          Forgot Password | The CBA Group
        </title>
      </Helmet>
      <Box sx={{backgroundColor: 'background.paper', flex: '1 1 auto', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{maxWidth: 550, px: 3, py: '100px', width: '100%'}}>
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">
                Forgot Password
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
              </Stack>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                {loadingSendEmail ? (
                  <CircularProgress style={{color: 'white'}} size='1.6rem' />
                ) : (
                  <>
                    Reset
                  </>
                )}
              </Button>

              <Button onClick={() => navigate("/login", { replace: true })} sx={{backgroundColor: 'white'}}>
                Login
              </Button>
            </form>
          </div>
        </Box>
      </Box>

      <Snackbar open={emailSent} autoHideDuration={6000} onClose={() => setEmailSent(false)}>
        <Alert onClose={() => setEmailSent(false)} severity="success" sx={{ width: '100%' }}>
          Password reset email sent!
        </Alert>
      </Snackbar>
    </>
  )
}