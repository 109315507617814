import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Used to initialize firebase auth
import {
  collection, 
  query, 
  getDocs,
  addDoc,
  getDoc,
  doc,
  setDoc,
  where
} from 'firebase/firestore'

import {db} from '../Firebase/Firebase';

import { 
  getAuth, 
  setPersistence, 
  signInWithEmailAndPassword, 
  browserSessionPersistence, 
  onAuthStateChanged,
  sendPasswordResetEmail
} from "firebase/auth";


const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
};

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...(
        // if payload (user) is provided, then is authenticated
        user
          ? ({
            isAuthenticated: true,
            isLoading: false,
            user
          })
          : ({
            isLoading: false
          })
      )
    };
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
};

const reducer = (state, action) => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

// The role of this context is to propagate authentication state through the App tree.
export const AuthContext = createContext({ undefined });

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);
  const navigate = useNavigate()

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;

    onAuthStateChanged(getAuth(), (authUser) => {
      if (authUser) {
        // const uid = user.uid;
        window.sessionStorage.setItem('authenticated', 'true');
        const user = {
          id: authUser.uid,
        };
        dispatch({
          type: HANDLERS.INITIALIZE,
          payload: user
        });
      } else {
        window.sessionStorage.setItem('authenticated', 'false');
        navigate("/login", { replace: true });
        dispatch({
          type: HANDLERS.INITIALIZE
        });
      }
    });
  };

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = async (email, password) => {
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence);

    // Checking if account is admin account
    const q = query(collection(db, "admins"), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.size !== 1){
      throw new Error('Not an admin acount');
      return
    }

    const result = await signInWithEmailAndPassword(auth, email, password)
      .then((fbUser) => {
        window.sessionStorage.setItem('authenticated', 'true');
        const user = {
          id: fbUser.user.uid,
        };
        dispatch({
          type: HANDLERS.SIGN_IN,
          payload: user
        });
      })
      .catch(function (error) {
        var errorCode = error.code;
        let errorMessage = ''
        switch(errorCode){
          case 'auth/invalid-email':
            errorMessage = 'Invalid Email.'
            break;
          case 'auth/wrong-password':
            errorMessage = 'Wrong Password.'
            break;
          case 'auth/user-not-found':
            errorMessage = 'User Not Found.'
            break;
          default:
            errorMessage = 'Error'
        }
        throw new Error(errorMessage);
      });
  
    return result
 
  };

  const signUp = async (email, name, password) => {
    throw new Error('Sign up is not implemented');
  };

  const resetPassword = async (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
    .catch(error => {
      throw new Error(error);
    })
  }

  const signOut = () => {
    getAuth().signOut()
    window.sessionStorage.setItem('authenticated', 'false');
    dispatch({
      type: HANDLERS.SIGN_OUT
    });
  };

  return (
    <AuthContext.Provider value={{...state, signIn, signUp, signOut, resetPassword}}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);
