import { useCallback, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../hooks/use-auth';

export default function Login() {
  const navigate = useNavigate()
  const auth = useAuth()

  const [loadingLogin, setLoadingLogin] = useState(false)


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required')
    }),
    onSubmit: async (values, helpers) => {
      setLoadingLogin(true)
      try {
        await auth.signIn(values.email, values.password);
        navigate("/contracts", { replace: true });
        setLoadingLogin(false)
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoadingLogin(false)
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>
          Login | The CBA Group
        </title>
      </Helmet>
      <Box sx={{backgroundColor: 'background.paper', flex: '1 1 auto', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{maxWidth: 550, px: 3, py: '100px', width: '100%'}}>
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">
                Admin Login
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              {formik.errors.submit && (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </Typography>
              )}
              <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                {loadingLogin ? (
                  <CircularProgress style={{color: 'white'}} size='1.6rem' />
                ) : (
                  <>
                    Login
                  </>
                )}
              </Button>

              <Button onClick={() => navigate("/forgot-password", { replace: true })} sx={{backgroundColor: 'white'}}>
                Forgot Password?
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </>
  )
}