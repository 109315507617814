import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link,
  Typography
} from '@mui/material';
import { Scrollbar } from '../components/scrollbar';

export const DocumentsTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
  } = props;

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "200px" }}>Name</TableCell>
              <TableCell style={{ width: "150px" }}>Type</TableCell>
              <TableCell style={{ width: "50px" }}>Year</TableCell>
              <TableCell style={{ width: "100px" }}>Date Uploaded</TableCell>
              <TableCell style={{ width: "50px" }}>File Ext.</TableCell>
              <TableCell style={{ width: "600px" }}>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((document) => {
              return (
                <TableRow hover key={document.id}>
                  <TableCell>
                    {document.filename}
                  </TableCell>
                  <TableCell style={{ width: '2rem' }}>
                    {document.type}
                  </TableCell>
                  <TableCell>
                    {document.year}
                  </TableCell>
                  <TableCell>
                    {document.uploadDate}
                  </TableCell>
                  <TableCell>
                    {document.fileExtension}
                  </TableCell>
                  <TableCell>
                    <Link href={document.url} target='_blank'>
                      {document.url}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

DocumentsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array
};
