import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useNavigate, Link } from "react-router-dom";

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Scrollbar } from '../components/scrollbar';
import { getInitials } from '../utils/get-initials';

export const BusinessesTable = (props) => {
  const {
    count = 0,
    items = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {},
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page = 0,
    rowsPerPage = 0,
  } = props;

  const navigate = useNavigate()


  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Address
              </TableCell>
              <TableCell>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((business) => {

              return (
                  <TableRow style={{textDecoration: 'none', color: 'black'}} component={Link} to={`/businesses/${business.id}`} state={{ id: business.id }} hover key={business.id}>
                    <TableCell>
                      {business.businessName}
                    </TableCell>
                    <TableCell>
                      {business.address}
                    </TableCell>
                    <TableCell>
                      {business.businessDescription}
                    </TableCell>
                  </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

BusinessesTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
