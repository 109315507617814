import React, { useCallback, useState } from "react";
import html2pdf from "html2pdf.js"
import cbaLogo2 from './CBALogo2.png';
import { Helmet } from "react-helmet";

import { 
  Grid,
  Button,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function ContractsCorporateResolutionToSell() {

  const [values, setValues] = useState({
    businessName: '',
    businessCity: '',
    businessCounty: '',
    employeeName: ''
  });


  const [day, setDay] = useState('01');
  const [month, setMonth] = useState('January');
  const [year, setYear] = useState('2023');
  const [error, setError] = useState('');

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

  const handleChange = useCallback(
  (event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, [])

  function generateCorporateResolutionToSell(){

    if(values.businessName === ""){
      setError("Enter a business name")
      setTimeout(() => setError(''), 6000);
      return
    }
    else if(values.businessCity === ""){
      setError("Enter a business city")
      setTimeout(() => setError(''), 6000);
      return
    }
    else if(values.businessCounty === ""){
      setError("Enter a business county")
      setTimeout(() => setError(''), 6000);
      return
    }
    else if(values.employeeName === ""){
      setError("Enter an employee name")
      setTimeout(() => setError(''), 6000);
      return
    }
    else{
      setError("")
    }
     
    var element = document.getElementById('corporateResolutionToSellContent');

    html2pdf(element, {
      filename: 'Corporate Resolution To Sell',
      html2canvas: { scale: 2,  logging: true },
      }).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'), '_blank');
    })
  }

  return (
    <>
      <Helmet>
        <title>
          Corporate Resolution to Sell
        </title>
      </Helmet>
      
      <Grid container spacing={1} pl={2}>
        <Grid item xs={4}>
          <Typography variant="h5">
            Corporate Resolution to Sell
          </Typography>
          <Grid pt={4} pb={4} container spacing={1} >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  value={month}
                  label="Month"
                  onChange={(e) => setMonth(e.target.value)}
                >
                  {months.map((month) => {
                    return <MenuItem key={month} value={month}>{month}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Day</InputLabel>
                <Select
                  value={day}
                  label="Day"
                  onChange={(e) => setDay(e.target.value)}
                >
                  {days.map((day) => {
                    return <MenuItem key={day} value={day}>{day}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Year</InputLabel>
                <Select
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value={'2026'}>{'2026'}</MenuItem>
                  <MenuItem value={'2025'}>{'2025'}</MenuItem>
                  <MenuItem value={'2024'}>{'2024'}</MenuItem>
                  <MenuItem value={'2023'}>{'2023'}</MenuItem>
                  <MenuItem value={'2022'}>{'2022'}</MenuItem>
                  <MenuItem value={'2021'}>{'2021'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business Name"
                name="businessName"
                onChange={handleChange}
                value={values.businessName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="City"
                name="businessCity"
                onChange={handleChange}
                value={values.businessCity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="County"
                name="businessCounty"
                onChange={handleChange}
                value={values.businessCounty}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Employee Name"
                name="employeeName"
                onChange={handleChange}
                value={values.employeeName}
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                fullWidth
                sx={{marginTop: '15px', borderRadius: '5px', backgroundColor: 'green', color: 'white'}} 
                onClick={() => generateCorporateResolutionToSell()}
              >
                Generate PDF
              </Button>
            </Grid>
            <Grid item xs={12}>
              <p style={{fontSize: 25, color: 'red', textAlign: 'center'}}>{error}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <div id="corporateResolutionToSellContent" style={{width: '768px'}}>

            <img style={{height: 100, width: 210, marginLeft: 300, marginTop: 30}} src={cbaLogo2}></img>

            <p style={{textAlign: 'center', marginLeft: 150, width: 500, marginTop: 20, fontSize: 14, fontWeight: 'bold'}}>
              The CBA Group, LLC &nbsp; 20462 Chartwell Center Dr. Suite C, Cornelius, NC 28031 Phone: (704) 895-4274 Fax: (704) 895-4278
            </p>

            <p style={{width: 450, marginLeft: 235, fontSize: 25, fontWeight: 'bold'}}>
              Corporate Resolution To Sell
            </p>

            <p style={{fontsize: 13, width: 625, marginLeft: 75, textAlign: 'center'}}>
              The undersigned, being the Secretary of <strong>{values.businessName}</strong>, a 
              North Carolina Corporation and acting to North Carolina General Statutes Sections 55-7-04, 55-8-21 and 55-12-02, does hereby certify 
              that this is a true and correct resolution unanimously adopted by a joint meeting of the Shareholders and the 
              Board of Directors of the Corporation, at a special business meeting held pursuant to notice duly given in the 
              city of <strong>{values.businessCity}</strong>  in the County of 
              <strong> {values.businessCounty}</strong>, North Carolina, on the 
              <strong> {day}</strong> day of <strong>{month}</strong>, <strong>{year}</strong>, and 
              this resolution will not be revoked by any subsequent action of the Board of Directors of the Corporation, but will 
              remain in full force and effect.
            </p>

            <p style={{width: 500, marginLeft: 75, fontSize: 15, marginTop: 30}}>
              BE IT RESOLVED that <strong>{values.employeeName}</strong> of the Corporation is 
              hereby authorized and directed with the full and complete authority to:
            </p>

            <ol style={{width: 625, marginLeft: 75, marginTop: 30}}>
              <li data-list-text="1)">
                <p style={{fontSize: 16}}>
                  Sell any or all assets of the Corporation.
                </p> 
              </li>
              <li data-list-text="2)">
                <p style={{fontSize: 16}}>Execute an agreement to pay a fee to The CBA Group, LLC, and/or to The CBA 
                  Group Real Estate, in the event that the business and/or real property of the Corporation is disposed 
                  of under authority given to them in a Listing Contract, Commission Protection Plan or other Agreement.
                </p>
              </li>
              <li  data-list-text="3)">
                <p style={{fontSize: 16}}>Execute a contract for the sale, lease or exchange of the assets of the 
                  Corporation at such price, term and conditions as he/she, in his/her sole discretion, deems 
                  acceptable and thereafter to execute any and all documents necessary to complete the sale, lease 
                  or exchange.
                </p>
              </li>
            </ol>

            <p style={{fontSize: 15, marginLeft: 75, textDecoration: "underline", marginTop: 70, marginBottom: 0, padding: 0}}>
              {values.employeeName}
            </p>

            <div style={{marginLeft: 75, display: "flex"}}>
              <div style={{width: 200}}>
                <p style={{fontSize: 15, width: 200}}>
                  Printed Name
                </p>
              </div>

              <div style={{width: 300, marginLeft: '5%', borderTop: "1px solid black"}}>
                <p style={{width: 300, fontSize: 15}}>
                  <em>Signature</em> Secretary of the Corporation
                </p>
              </div>
            </div>

            <p style={{fontSize: 10, width: 90, marginLeft: 615, marginTop: 20}}>CBA Form 101719</p>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

