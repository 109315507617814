import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useAuth } from '../hooks/use-auth';

import { AuthContext } from '../contexts/auth-context';
import { useContext } from 'react';

import { app } from '../Firebase/Firebase'
import {
  getFirestore,
  doc,
  getDoc
} from 'firebase/firestore'

import {db} from '../Firebase/Firebase';
// const db = getFirestore(app);


export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const navigate = useNavigate()
  const auth = useAuth();
  const { user } = useContext(AuthContext);

  const [name, setName] = useState('')

  useEffect(() => {
    const getUserName = async () => { 
      const docRef = doc(db, "admins", user.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setName(docSnap.data().firstName + ' ' + docSnap.data().lastName)
      }
    }
    getUserName()
},[])

  const handleSignOut = useCallback(
    () => {
      onClose?.()
      auth.signOut()
      navigate("/login", { replace: true })
    },
    [onClose, auth, navigate]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">
          Account
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {name}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>
          Sign out
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
