import React, { useCallback, useState } from "react";
import html2pdf from "html2pdf.js"
import cbaLogo2 from './CBALogo2.png';
import { Helmet } from "react-helmet";

import { 
  Grid,
  Button,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';


import 'bootstrap/dist/css/bootstrap.min.css';

export default function ContractsBrokerConfidentiality() {

  const [values, setValues] = useState({
    clientName: '',
    brokerName: '',
  });


  const [day, setDay] = useState('01');
  const [month, setMonth] = useState('January');
  const [year, setYear] = useState('2023');
  const [error, setError] = useState('');

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

  const handleChange = useCallback(
  (event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, [])

  function generateBrokerConfidentiality(){

    if(values.brokerName === ""){
      setError("Enter a broker name")
      setTimeout(() => setError(''), 6000);
      return
    }
    else if(values.clientName === ""){
      setError("Enter a client name")
      setTimeout(() => setError(''), 6000);
      return
    }
    else{
      setError("")
    }

    var element = document.getElementById('brokerConfidentialityContent');

    html2pdf(element, {
      filename: 'Broker Confidentiality',
      html2canvas: { scale: 2,  logging: true },
      }).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'), '_blank');
    })

  }

  return (
    <>
      <Helmet>
        <title>
          Broker Confidentiality
        </title>
      </Helmet>
      
      <Grid container spacing={1} pl={2}>
        <Grid item xs={4}>
          <Typography variant="h5">
            Broker Confidentiality
          </Typography>
          <Grid pt={4} pb={4} container spacing={1} >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  value={month}
                  label="Month"
                  onChange={(e) => setMonth(e.target.value)}
                >
                  {months.map((month) => {
                    return <MenuItem key={month} value={month}>{month}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Day</InputLabel>
                <Select
                  value={day}
                  label="Day"
                  onChange={(e) => setDay(e.target.value)}
                >
                  {days.map((day) => {
                    return <MenuItem key={day} value={day}>{day}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Year</InputLabel>
                <Select
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value={'2026'}>{'2026'}</MenuItem>
                  <MenuItem value={'2025'}>{'2025'}</MenuItem>
                  <MenuItem value={'2024'}>{'2024'}</MenuItem>
                  <MenuItem value={'2023'}>{'2023'}</MenuItem>
                  <MenuItem value={'2022'}>{'2022'}</MenuItem>
                  <MenuItem value={'2021'}>{'2021'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Client Name"
                name="clientName"
                onChange={handleChange}
                value={values.clientName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Broker Name"
                name="brokerName"
                onChange={handleChange}
                value={values.brokerName}
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                fullWidth
                sx={{marginTop: '15px', borderRadius: '5px', backgroundColor: 'green', color: 'white'}} 
                onClick={() => generateBrokerConfidentiality()}
              >
                Generate PDF
              </Button>
            </Grid>
            <Grid item xs={12}>
              <p style={{fontSize: 25, color: 'red', textAlign: 'center'}}>{error}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <div id="brokerConfidentialityContent" style={{width: '768px'}}>

            <img style={{height: 100, width: 210, marginLeft: 300, marginTop: 30}} src={cbaLogo2}></img>

            <p style={{textAlign: 'center', marginLeft: 150, width: 500, marginTop: 20, fontSize: 14, fontWeight: 'bold'}}>
              The CBA Group, LLC &nbsp; 20462 Chartwell Center Dr. Suite C, Cornelius, NC 28031 Phone: (704) 895-4274 Fax: (704) 895-4278
            </p>

            <p style={{width: 500, marginLeft: 210, fontSize: 25, fontWeight: 'bold'}}>
              Broker Confidentiality Agreement
            </p>

            <p style={{fontsize: 13, width: 625, marginLeft: 75, textAlign: 'center'}}>
              The undersigned Business Broker and <strong>The CBA Group, LLC </strong>
              agree that all information provided by <strong> {values.clientName} 
              </strong>, and any of its affiliates, shall be held in strict confidence by the broker, its 
              agents, officers, and employees. All financial statements and any other related information 
              shall be used for the sole purpose of the valuation of the above named Company. The Company 
              understands that if a photocopy of this information is sent to the valuation department of 
              any selected Valuation Company they also shall maintain total confidentiality.
            </p>
            <p style={{fontSize: 14, width: 500, marginLeft: 50, marginTop: 30}}>
              Signed and Agreed to this on the <strong> {day} </strong> 
              day of <strong>{month}, {year} 
              </strong>.</p>
            <p style={{fontSize: 13, width: 500, marginLeft: 50}}>
              Broker:
            </p>

            <div style={{width: 250, marginLeft: 50}}>
              <p style={{fontSize: 25, margin: 0, padding: 0, fontFamily: "Brush Script MT", textDecoration: "underline"}}>
                {values.brokerName}
              </p>
              <div style={{borderTopWidth: 1, borderTopColor: 'black'}}>
                <p style={{fontSize: 13, margin: 0, padding: 0}}>(Name)
                </p>
              </div>
            </div>

            <p style={{fontSize: 13, marginLeft: 50, width: 300, textDecoration: "underline", marginTop: 15, marginBottom: 0, padding: 0}}>
              20462 Chartwell Center Drive, Suite C
            </p>

            <p style={{fontSize: 13, marginLeft: 50, marginTop: 0, marginBottom: 0, padding: 0}}>
              (Address)
            </p>

            <p style={{fontSize: 13, marginLeft: 50, width: 300, textDecoration: "underline", marginTop: 15, marginBottom: 0, padding: 0}}>
              Cornelius, NC 28031
            </p>

            <p style={{fontSize: 13, marginLeft: 50, marginTop: 0, marginBottom: 0, padding: 0}}>
              (City/State/Zip)
            </p>

            <p style={{fontSize: 13, marginLeft: 50, width: 300, textDecoration: 'underline', marginTop: 15, marginBottom: 0, padding: 0}}>
              (704) 895-4274
            </p>

            <p style={{fontSize: 13, marginLeft: 50, marginTop: 0, marginBottom: 0, padding: 0}}>
              (Telephone)
            </p>

            <p style={{fontSize: 13, marginLeft: 50, width: 300, textDecoration: 'underline', marginTop: 15, marginBottom: 0, padding: 0}}>
              (704) 895-4278
            </p>

            <p style={{fontSize: 13, marginLeft: 50, marginTop: 0, marginBottom: 0, padding: 0}}>
              (Fax)
            </p>

            <p style={{fontSize: 12, width: 100, marginLeft: 625, marginTop: 190}}>CBA Form 2031</p>

          </div>
        </Grid>
      </Grid>
    </>
  )
}

