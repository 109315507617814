import { useCallback, useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs'
import PropTypes from 'prop-types';
import BellIcon from '@heroicons/react/24/solid/BellIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import Notification from './Notification';

import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { usePopover } from '../hooks/use-popover';
import { AccountPopover } from './account-popover';

import {
  doc,
  getDoc,
  getDocs,
  collection,
  query
} from 'firebase/firestore'
import {db} from '../Firebase/Firebase';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    
    const getBusinessInfo = async () => { 

      //  Getting all documents
      const notifications = []
      const documentQuery = query(collection(db, "documents"))
      const documentQuerySnapshot = await getDocs(documentQuery);
      documentQuerySnapshot.forEach(async (documentsDoc) => {
        if(isWithinLastWeek(documentsDoc.data().uploadDate)){
          // Get uploader name
          let name = ''
          const docRef = doc(db, "sellers", documentsDoc.data().uploaderId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            name = docSnap.data().firstName + ' ' + docSnap.data().lastName
          }

          const notification = {
            name: name,
            date: dayjs(documentsDoc.data().uploadDate).format('MMMM D'),
            time: dayjs(documentsDoc.data().uploadDate).format('hh:mm a'),
          }
          notifications.push(notification)
        }
      })
      setNotifications(notifications)
    }
    getBusinessInfo()
  },[]);

  function isWithinLastWeek(date) {
    const currentDate = dayjs();
    const lastWeekDate = currentDate.subtract(7, 'day');
    return dayjs(date).isAfter(lastWeekDate)
  }

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2} sx={{minHeight: TOP_NAV_HEIGHT, px: 2}}>
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Notification notifications={notifications} />
            <Avatar onClick={accountPopover.handleOpen} ref={accountPopover.anchorRef} sx={{cursor: 'pointer', height: 40, width: 40}} src="/assets/avatars/avatar-anika-visser.png"/>
          </Stack>
        </Stack>
      </Box>
      <AccountPopover anchorEl={accountPopover.anchorRef.current} open={accountPopover.open} onClose={accountPopover.handleClose}/>
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func
};
