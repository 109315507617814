import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Alert,
  Snackbar
} from '@mui/material';

import dayjs from 'dayjs'

import {
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore'

import {db} from '../Firebase/Firebase';

const yearsInBusiness = [
    {label: '1'},{label: '2'},{label: '3'},{label: '4'},{label: '5'},{label: '6'},{label: '7'},{label: '8'},
    {label: '9'},{label: '10'},{label: '11'},{label: '12'},{label: '13'},{label: '14'},
    {label: '15'},{label: '16'},{label: '17'},{label: '18'},{label: '19'},{label: '20'},{label: '21'},
    {label: '22'},{label: '23'},{label: '24'},{label: '25'},{label: '26'},{label: '27'},{label: '28'},
    {label: '29'},{label: '30'},{label: '31'},{label: '32'},{label: '33'},{label: '34'},{label: '35'
    },{label: '36'},{label: '37'},{label: '38'},{label: '39'},{label: '40'}
]

const numberOfEmployees = [
    {label: '1-5'},{label: '6-10'},{label: '11-15'},{label: '15+'},
]

export default function CreateSellerQuestionnaire() {

    const { state } = useLocation();

    const [questionnaireValues, setQuestionnaireValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        businessName: '',
        businessAddress: '',
        businessDescription: '',
        yearsInBusiness: '0',
        numberOfEmployees: '0',
        totalSales: '0',
        priceInMind: '0',
        priceInMindDetermination: '',
        inventoryValue: '0',
        totalCustomers: '0',
        leaseCost: '0',
        buildingValue: '0',
        website: ''
    });

    const [considerSelling, setConsiderSelling] = useState('No')
    const [consideredGettingEvaluation, setConsideredGettingEvaluation] = useState('No')
    const [carryInventory, setCarryInventory] = useState('No')
    const [isManager, setIsManager] = useState('No')
    const [isBusinessProfitable, setIsBusinessProfitable] = useState('No')
    const [corporationType, setCorporationType] = useState('C corp.')
    const [ownOrLeaseBuilding, setOwnOrLeaseBuilding] = useState('Lease')
    const [isLeaseAssignable, setIsLeaseAssignable] = useState('No')
    const [successMessageOpen, setSuccessMessageOpen] = useState(false)

    useEffect(() => {
    
        const getData = async () => { 
    
          const docRef = doc(db, "sellerQuestionnaires", state.id);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            setQuestionnaireValues({
                firstName: docSnap.data().firstName,
                lastName: docSnap.data().lastName,
                email: docSnap.data().email,
                phone: docSnap.data().phone,
                businessName: docSnap.data().businessName,
                businessAddress: docSnap.data().businessAddress,
                businessDescription: docSnap.data().businessDescription,
                yearsInBusiness: docSnap.data().yearsInBusiness,
                numberOfEmployees: docSnap.data().numberOfEmployees,
                totalSales: docSnap.data().totalSales,
                priceInMind: docSnap.data().priceInMind,
                priceInMindDetermination: docSnap.data().priceInMindDetermination,
                inventoryValue: docSnap.data().inventoryValue,
                totalCustomers: docSnap.data().totalCustomers,
                leaseCost: docSnap.data().leaseCost,
                buildingValue: docSnap.data().buildingValue,
                website: docSnap.data().website
            });

            setCorporationType(docSnap.data().corporationType)
            setOwnOrLeaseBuilding(docSnap.data().ownOrLeaseBuilding)
            setConsiderSelling(docSnap.data().considerSelling ? 'Yes' : 'No')
            setConsideredGettingEvaluation(docSnap.data().consideredGettingEvaluation ? 'Yes' : 'No')
            setCarryInventory(docSnap.data().carryInventory ? 'Yes' : 'No')
            setIsManager(docSnap.data().isManager ? 'Yes' : 'No')
            setIsBusinessProfitable(docSnap.data().isBusinessProfitable ? 'Yes' : 'No')
            setIsLeaseAssignable(docSnap.data().isLeaseAssignable ? 'Yes' : 'No')
          } 
          else {
            alert("Error: unable to get questionnaire data")
          }
        }
        getData()
    },[]);
    

    const handleChange = useCallback(
    (event) => {
        setQuestionnaireValues((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        //  TODO: Check for empty inputs

        // Add a new document with a generated id.
        await updateDoc(doc(db, "sellerQuestionnaires", state.id), {
            firstName: questionnaireValues.firstName,
            lastName: questionnaireValues.lastName,
            email: questionnaireValues.email,
            phone: questionnaireValues.phone,
            businessName: questionnaireValues.businessName,
            businessAddress: questionnaireValues.businessAddress,
            businessDescription: questionnaireValues.businessDescription,
            yearsInBusiness: parseInt(questionnaireValues.yearsInBusiness),
            numberOfEmployees: parseInt(questionnaireValues.numberOfEmployees),
            totalSales: parseInt(questionnaireValues.totalSales),
            priceInMind: parseInt(questionnaireValues.priceInMind),
            priceInMindDetermination: questionnaireValues.priceInMindDetermination,
            inventoryValue: parseInt(questionnaireValues.inventoryValue),
            totalCustomers: parseInt(questionnaireValues.totalCustomers),
            leaseCost: parseInt(questionnaireValues.leaseCost),
            buildingValue: parseInt(questionnaireValues.buildingValue),
            website: questionnaireValues.website,
            considerSelling: considerSelling === "Yes" ? true : false,
            consideredGettingEvaluation: consideredGettingEvaluation === "Yes" ? true : false,
            carryInventory: carryInventory === "Yes" ? true : false,
            isManager: isManager === "Yes" ? true : false,
            isBusinessProfitable: isBusinessProfitable === "Yes" ? true : false,
            corporationType: corporationType,
            ownOrLeaseBuilding: ownOrLeaseBuilding,
            isLeaseAssignable: isLeaseAssignable === "Yes" ? true : false,
        })
        .then(() => {
            //  Display success message
            setSuccessMessageOpen(true)
            setTimeout(() => setSuccessMessageOpen(false), 4000);
        })
    }

    return (
    <>
        <Helmet>
            <title>
                Questionnaire | The CBA Group
            </title>
        </Helmet>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card>
                <Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={() => successMessageOpen(false)}>
                    <Alert onClose={() => successMessageOpen(false)} severity="success" sx={{ width: '100%' }}>
                        Questionnaire Completed!
                    </Alert>
                </Snackbar>
                <CardHeader subheader="The questionnaire will be saved for later use" title="Seller Questionnaire"/>
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid container spacing={3} >
                            <Grid xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    onChange={handleChange}
                                    required
                                    value={questionnaireValues.firstName}
                                />
                            </Grid>
                            <Grid xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    onChange={handleChange}
                                    required
                                    value={questionnaireValues.lastName}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    name="email"
                                    onChange={handleChange}
                                    required
                                    value={questionnaireValues.email}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Business Name"
                                    name="businessName"
                                    onChange={handleChange}
                                    required
                                    value={questionnaireValues.businessName}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="phone"
                                    onChange={handleChange}
                                    type="phone"
                                    value={questionnaireValues.phone}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Business Address"
                                    name="businessAddress"
                                    onChange={handleChange}
                                    type="address"
                                    value={questionnaireValues.businessAddress}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    label="Business Description"
                                    name="businessDescription"
                                    onChange={handleChange}
                                    multiline
                                    rows={5}
                                    value={questionnaireValues.businessDescription}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Years in Business"
                                    name="yearsInBusiness"
                                    onChange={handleChange}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={questionnaireValues.yearsInBusiness}
                                >
                                    {yearsInBusiness.map((option) => (
                                        <option key={option.label} value={option.label}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Number of Employees"
                                    name="numberOfEmployees"
                                    onChange={handleChange}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={questionnaireValues.numberOfEmployees}
                                >
                                    {numberOfEmployees.map((option) => (
                                        <option key={option.label} value={option.label}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Approx. Annual Sales"
                                    name="totalSales"
                                    onChange={handleChange}
                                    type="number"
                                    value={questionnaireValues.totalSales}
                                />
                            </Grid>
                            <Grid alignContent={'center'} xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Would you consider selling</FormLabel>
                                    <RadioGroup value={considerSelling} onChange={(event) => setConsiderSelling(event.target.value)}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid alignContent={'center'} xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Have you considered getting an evaluation?</FormLabel>
                                    <RadioGroup value={consideredGettingEvaluation} onChange={(event) => setConsideredGettingEvaluation(event.target.value)}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Price In Mind"
                                    name="priceInMind"
                                    onChange={handleChange}
                                    type="number"
                                    value={questionnaireValues.priceInMind}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="How did you determine that value?"
                                    name="priceInMindDetermination"
                                    onChange={handleChange}
                                    value={questionnaireValues.priceInMindDetermination}
                                />
                            </Grid>
                            <Grid alignContent={'center'} xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Do you carry inventory?</FormLabel>
                                    <RadioGroup value={carryInventory} onChange={(event) => setCarryInventory(event.target.value)}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {carryInventory === 'Yes' && (
                                <Grid xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Est. value of Inventory"
                                        name="inventoryValue"
                                        onChange={handleChange}
                                        type="number"
                                        value={questionnaireValues.inventoryValue}
                                    />
                                </Grid>
                            )}
                        
                            <Grid xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Are you the Manager?</FormLabel>
                                    <RadioGroup value={isManager} onChange={(event) => setIsManager(event.target.value)}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Total Customers"
                                    name="totalCustomers"
                                    onChange={handleChange}
                                    type="number"
                                    value={questionnaireValues.totalCustomers}
                                />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Is the business profitable?</FormLabel>
                                    <RadioGroup value={isBusinessProfitable} onChange={(event) => setIsBusinessProfitable(event.target.value)}>
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>What type of corporation is the business?</FormLabel>
                                    <RadioGroup value={corporationType} onChange={(event) => setCorporationType(event.target.value)}>
                                        <FormControlLabel value="C corp." control={<Radio />} label="C corp." />
                                        <FormControlLabel value="S corp." control={<Radio />} label="S corp." />
                                        <FormControlLabel value="LLC" control={<Radio />} label="LLC" />
                                        <FormControlLabel value="Sole Proprietor" control={<Radio />} label="Sole Proprietor" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Do you lease or own the building?</FormLabel>
                                    <RadioGroup value={ownOrLeaseBuilding} onChange={(event) => setOwnOrLeaseBuilding(event.target.value)}>
                                        <FormControlLabel value="Lease" control={<Radio />} label="Lease" />
                                        <FormControlLabel value="Own" control={<Radio />} label="Own" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {ownOrLeaseBuilding === "Own" ? (
                                <Grid xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Est. Value of Building"
                                        name="buildingValue"
                                        onChange={handleChange}
                                        type="number"
                                        value={questionnaireValues.buildingValue}
                                    />
                                </Grid>
                            ) : (
                                <Grid xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Lease Cost"
                                        name="leaseCost"
                                        onChange={handleChange}
                                        type="number"
                                        value={questionnaireValues.leaseCost}
                                    />
                                </Grid>
                            )}
                            <Grid xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>Is the lease assignable?</FormLabel>
                                    <RadioGroup value={isLeaseAssignable} onChange={(event) => setIsLeaseAssignable(event.target.value)}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label="Website"
                                    name="website"
                                    onChange={handleChange}
                                    value={questionnaireValues.website}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>


                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button type='submit' variant="contained">
                        Update
                    </Button>
                </CardActions>
            </Card>
        </form>
    </>
    );
}