import React, { useCallback, useState } from "react";
import html2pdf from "html2pdf.js"
import cbaLogo2 from './CBALogo2.png';
import { FaBackspace } from 'react-icons/fa';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import { RiDeleteBin6Line } from "react-icons/ri";
import { Helmet } from "react-helmet";

import { 
  Grid,
  Button,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';


import 'bootstrap/dist/css/bootstrap.min.css';
import './Contracts.css'

const contractButton = {
  backgroundColor: '#45aa42',
  color: 'white',
  borderRadius: '5px'
}

export default function ContractsCashFlowAnalysis() {

  const [cashflowAnalysisFiscalSalesDay, setCashflowAnalysisFiscalSalesDay] = useState('01');
  const [cashflowAnalysisFiscalSalesMonth, setCashflowAnalysisFiscalSalesMonth] = useState('01');
  const [cashflowAnalysisFiscalSalesYear, setCashflowAnalysisFiscalSalesYear] = useState('2023');

  /////   For editing cash flow input rows    /////
  const [cashflowAnalysisEditInputsVisible, setCashflowAnalysisEditInputsVisible] = useState(false);
  const [cashflowAnalysisInputArray, setCashflowAnalysisInputArray] = useState([
      {type: "Fringe", title: "Owner's Vehicle", value: "0", id: '0'},
      {type: "Fringe", title: "Owner's Insurance", value: "0", id: '1'},
      {type: "Fringe", title: "Owner's Medical", value: "0", id: '2'},
      {type: "Fringe", title: "Owner's Payroll Taxes", value: "0", id: '3'},
      {type: "Fringe", title: "Owner's Travel/Entertainment", value: "0", id: '4'},
      {type: "Fringe", title: "Non-Business Telephone", value: "0", id: '5'},
      {type: "Fringe", title: "Non-Business Utilities", value: "0", id: '6'},
      {type: "Fringe", title: "Non-Business Legal", value: "0", id: '7'},
      {type: "Fringe", title: "Non-Business Accounting", value: "0", id: '8'},
      {type: "Other", title: "Partner/Family Excess Salary", value: "0", id: '11'},
      {type: "Other", title: "Non Real Salaries", value: "0", id: '12'},
      {type: "Other", title: "Family Benefits", value: "0", id: '13'},
      {type: "Other", title: "Donations", value: "0", id: '14'},
      {type: "Other", title: "Non Recurring", value: "0", id: '15'},
      {type: "Other", title: "Rent Adjustments", value: "0", id: '16'},
      {type: "Other", title: "Inventory Adjustments", value: "0", id: '17'},
  ])


  const [cashFlowValues, setCashFlowValues] = useState({
    cashflowAnalysisBusinessName: '',
    cashflowAnalysisFiscalYearSales: '0',
    cashflowAnalysisNetOperatingIncome: '0',
    cashflowAnalysisOwnerSalary: '0',
    cashflowAnalysisOwnerVehicle: '0',
    cashflowAnalysisOwnerInsurance: "0",
    cashflowAnalysisOwnerMedical: "0",
    cashflowAnalysisOwnerPayrollTaxes: "0",
    cashflowAnalysisOwnerTravelEntertainment: "0",
    cashflowAnalysisNonBusinessTelephone: "0",
    cashflowAnalysisNonBusinessUtilities: "0",
    cashflowAnalysisNonBusinessLegal: "0",
    cashflowAnalysisNonBusinessAccounting: "0",
    cashflowAnalysisInterestExpense: "0",
    cashflowAnalysisDeprecationAmortization: "0",
    cashflowAnalysisPartnerFamilyExcessSalary: "0",
    cashflowAnalysisNonRealSalaries: "0",
    cashflowAnalysisFamilyBenefits: "0",
    cashflowAnalysisDonations: "0",
    cashflowAnalysisNonRecurring: "0",
    cashflowAnalysisRentAdjustments: "0",
    cashflowAnalysisInventoryAdjustments: "0",
    cashflowAnalysisError: "",
  });

  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

  const deleteCashFlowTitleButton = {
    color: 'red',
    borderRadius: '5px',
  }


  const handleChange = useCallback(
  (event) => {
    setCashFlowValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, [])

  function updateCashFlowAnalysisInputs(event, id){
    let tempCashflowAnalysisInputArray = [...cashflowAnalysisInputArray]

    for(let i =0; i<tempCashflowAnalysisInputArray.length; i++){
      if(tempCashflowAnalysisInputArray[i].id === id){
        tempCashflowAnalysisInputArray[i].value = event.target.value
      }
    }

    setCashflowAnalysisInputArray(tempCashflowAnalysisInputArray)
  }

  function updateCashFlowAnalysisInputsTitle(event, id){
    let tempCashflowAnalysisInputArray = [...cashflowAnalysisInputArray]

    for(let i =0; i<tempCashflowAnalysisInputArray.length; i++){
      if(tempCashflowAnalysisInputArray[i].id === id){
        tempCashflowAnalysisInputArray[i].title= event.target.value
      }
    }
    setCashflowAnalysisInputArray(tempCashflowAnalysisInputArray)
  }

  function deleteCashFlowInputRow(title){
    let tempCashflowAnalysisInputArray = [...cashflowAnalysisInputArray]

    for(var i =0; i<tempCashflowAnalysisInputArray.length; i++){
      if(tempCashflowAnalysisInputArray[i].title === title){
        tempCashflowAnalysisInputArray.splice(i, 1)
      }
    }

    setCashflowAnalysisInputArray(tempCashflowAnalysisInputArray)
  }

  function calculateTotalFringe(){
    var totalFringe = 0
    for(var i =0; i<cashflowAnalysisInputArray.length; i++){
      if(cashflowAnalysisInputArray[i].type === "Fringe"){
        totalFringe += parseInt(cashflowAnalysisInputArray[i].value)
      }
    }
    return totalFringe
  }

  function calculateTotalOther(){
    var totalOther = 0
    for(var i =0; i<cashflowAnalysisInputArray.length; i++){
      if(cashflowAnalysisInputArray[i].type === "Other"){
        totalOther += parseInt(cashflowAnalysisInputArray[i].value)
      }
    }
    return totalOther
  }

  function generateCashflow(){

    if(cashFlowValues.cashflowAnalysisBusinessName === ""){
      setCashFlowValues({cashflowAnalysisError: "Please add a business name"})
      setTimeout(() => setCashFlowValues({cashflowAnalysisError: ""}), 6000);
      return
    }
    if(cashFlowValues.cashflowAnalysisFiscalYearSales === "0" || cashFlowValues.cashflowAnalysisFiscalYearSales === ""){
      setTimeout(() => setCashFlowValues({cashflowAnalysisError: ""}), 6000);
      setCashFlowValues({cashflowAnalysisError: "Please add business fiscal sales"})
      return
    }
    if(cashFlowValues.cashflowAnalysisNetOperatingIncome === "0" || cashFlowValues.cashflowAnalysisNetOperatingIncome === ""){
      setTimeout(() => setCashFlowValues({cashflowAnalysisError: ""}), 6000);
      setCashFlowValues({cashflowAnalysisError: "Please add business net operating income"})
      return
    }


    var element = document.getElementById('cashFlowContent');

    html2pdf(element, {
      filename: 'Cash Flow',
      html2canvas: { scale: 2,  logging: true },
      }).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'), '_blank');
    })
  }

  function separator(numb) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }

  return(
    <>
      <Helmet>
        <title>
          Cash Flow Analysis
        </title>
      </Helmet>
      
      <Grid container spacing={1} pl={2}>
        <Grid item xs={4}>
          <Typography variant="h4">
            Cash Flow Analysis
          </Typography>
          <Typography pt={2} variant="body1" color='red'>
            WHOLE NUMBERS ONLY, NO COMMAS OR DOLLAR SIGNS($)
          </Typography>
          <TextField
            sx={{marginTop: '20px'}}
            fullWidth
            label="Business Name"
            name="cashflowAnalysisBusinessName"
            onChange={handleChange}
            value={cashFlowValues.cashflowAnalysisBusinessName}
          />
          <Grid pt={2} pb={4} container spacing={1} >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  value={cashflowAnalysisFiscalSalesMonth}
                  label="Month"
                  onChange={(e) => setCashflowAnalysisFiscalSalesMonth(e.target.value)}
                >
                  {months.map((month) => {
                    return <MenuItem key={month} value={month}>{month}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Day</InputLabel>
                <Select
                  value={cashflowAnalysisFiscalSalesDay}
                  label="Day"
                  onChange={(e) => setCashflowAnalysisFiscalSalesDay(e.target.value)}
                >
                  {days.map((day) => {
                    return <MenuItem key={day} value={day}>{day}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Year</InputLabel>
                <Select
                  value={cashflowAnalysisFiscalSalesYear}
                  label="Year"
                  onChange={(e) => setCashflowAnalysisFiscalSalesYear(e.target.value)}
                >
                  <MenuItem value={'2026'}>{'2026'}</MenuItem>
                  <MenuItem value={'2025'}>{'2025'}</MenuItem>
                  <MenuItem value={'2024'}>{'2024'}</MenuItem>
                  <MenuItem value={'2023'}>{'2023'}</MenuItem>
                  <MenuItem value={'2022'}>{'2022'}</MenuItem>
                  <MenuItem value={'2021'}>{'2021'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Year Sales"
                name="cashflowAnalysisFiscalYearSales"
                onChange={handleChange}
                value={cashFlowValues.cashflowAnalysisFiscalYearSales}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Net Income"
                name="cashflowAnalysisNetOperatingIncome"
                onChange={handleChange}
                value={cashFlowValues.cashflowAnalysisNetOperatingIncome}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Owner Salary"
                name="cashflowAnalysisOwnerSalary"
                onChange={handleChange}
                value={cashFlowValues.cashflowAnalysisOwnerSalary}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Interest Expense"
                name="cashflowAnalysisInterestExpense"
                onChange={handleChange}
                value={cashFlowValues.cashflowAnalysisInterestExpense}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Deprecation & Amortization"
                name="cashflowAnalysisDeprecationAmortization"
                onChange={handleChange}
                value={cashFlowValues.cashflowAnalysisDeprecationAmortization}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography pt={4} variant="h5">
                Fringe Benefits
              </Typography>
            </Grid>
            {cashflowAnalysisInputArray.map((input) => {
              return (
                input.type === 'Fringe' && (
                  <Grid key={input.id} item xs={6}>
                    {cashflowAnalysisEditInputsVisible && (
                      <Stack direction='row'>
                        <Button onClick={() => deleteCashFlowInputRow(input.title)} sx={deleteCashFlowTitleButton}>
                          <RiDeleteBin6Line size={20}/>
                        </Button>
                        <TextField
                          fullWidth
                          type="text" 
                          value={input.title} 
                          onChange={(event) => updateCashFlowAnalysisInputsTitle(event, input.id)}
                          label='Change Title'
                        />
                      </Stack>
                    )}
                    <TextField
                      fullWidth
                      label={input.title}
                      name={input.title}
                      onChange={(event) => updateCashFlowAnalysisInputs(event, input.id)}
                      value={input.value}
                    />
                  </Grid>
                )
              )
            })}

            <Grid item xs={12}>
              <Typography pt={4} variant="h5">
                Other
              </Typography>
            </Grid>
            {cashflowAnalysisInputArray.map((input) => {
              return (
                input.type === 'Other' && (
                  <Grid key={input.id} item xs={6}>
                    {cashflowAnalysisEditInputsVisible && (
                      <Stack direction='row'>
                        <Button onClick={() => deleteCashFlowInputRow(input.title)} sx={deleteCashFlowTitleButton}>
                          <RiDeleteBin6Line size={20}/>
                        </Button>
                        <TextField
                          fullWidth
                          type="text" 
                          value={input.title} 
                          onChange={(event) => updateCashFlowAnalysisInputsTitle(event, input.id)}
                          label='Change Title'
                        />
                      </Stack>
                    )}
                    <TextField
                      fullWidth
                      label={input.title}
                      name={input.title}
                      onChange={(event) => updateCashFlowAnalysisInputs(event, input.id)}
                      value={input.value}
                    />
                  </Grid>
                )
              )
            })}

            <Grid item xs={6}>
              <Button 
                fullWidth
                onClick={() => setCashflowAnalysisEditInputsVisible(!cashflowAnalysisEditInputsVisible)}
                sx={{marginTop: '15px', borderRadius: '5px', backgroundColor:  cashflowAnalysisEditInputsVisible ? 'green' : '#0099FF', color: 'white'}}
              >
                {cashflowAnalysisEditInputsVisible ? 'Done' : 'Edit Titles'}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button 
                fullWidth
                sx={{marginTop: '15px', borderRadius: '5px', backgroundColor: 'green', color: 'white'}} 
                onClick={() => generateCashflow()}
              >
                Generate PDF
              </Button>
            </Grid>

          </Grid>

        </Grid>
        <Grid item xs={8}>
          <div id="cashFlowContent" style={{width: '768px'}}>

            <img style={{height: 100, width: 210, marginLeft: 300, marginTop: 30}} src={cbaLogo2}></img>

            <p style={{textAlign: 'center', marginLeft: 150, width: 500, marginTop: 20, fontSize: 14, fontWeight: 'bold'}}>
              The CBA Group, LLC 20462 Chartwell Center Dr. Suite C, Cornelius, NC 28031 Phone: (704) 895-4274 Fax: (704) 895-4278
            </p>
            <p style={{width: 500, marginLeft: 195, fontSize: 18, marginBottom: 5, marginTop: 10, fontWeight: 'bold'}}>
              TOTAL OWNER BENEFIT “CASH FLOW” ANALYSIS 
            </p>

            <p style={{fontSize: 16, marginLeft: 75, marginBottom: 0}}>
              Business Name: <strong>{cashFlowValues.cashflowAnalysisBusinessName}</strong>
            </p>

            <div style={{width: 500, marginLeft: 75, display: 'flex'}}>
              <p style={{fontSize: 16, margin: 0, width: 250}}>
                Fiscal Year Sales
              </p>
              <p style={{fontSize: 16, margin: 0}}>
                ${separator(parseInt(cashFlowValues.cashflowAnalysisFiscalYearSales))}
              </p>
            </div>

            <div style={{width: 600, marginLeft: 75, display: 'flex'}}>
              <p style={{fontSize: 15, margin: 0, width: 230}}>
                Net Operating Income for fiscal year ending:
              </p>
              <p style={{fontSize: 15, margin: 0, width: 200, fontWeight: 'bold'}}>
                {cashflowAnalysisFiscalSalesMonth}/{cashflowAnalysisFiscalSalesDay}/{cashflowAnalysisFiscalSalesYear}
              </p>
              <p style={{fontSize: 15, margin: 0, width: 45}}>
                . . . . . .
              </p>
              <p style={{fontSize: 16, margin: 0, fontWeight: 'bold'}}>
                ${separator(parseInt(cashFlowValues.cashflowAnalysisNetOperatingIncome))}
              </p>
            </div>

            <div style={{width: 600, marginLeft: 75, display: 'flex'}}>
              <p style={{fontSize: 16, margin: 0, width: 200}}>
                Owners Salary per P&L only
              </p>
              <p style={{fontSize: 15, margin: 0, width: 275}}>
                . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
              </p>
              <p style={{fontSize: 16, margin: 0, fontWeight: 'bold'}}>
                ${separator(parseInt(cashFlowValues.cashflowAnalysisOwnerSalary))}
              </p>
            </div>

            <p style={{fontSize: 16, width: 200, marginLeft: 75, marginTop: 5, marginBottom: 0}}>
              Fringe Benefits
            </p>

            {cashflowAnalysisInputArray.map((input, key) => {
              if(input.type === "Fringe"){
                return(
                  <div key={key} style={{width: 500, marginLeft: 100, display: 'flex'}}>
                    <p style={{fontSize: 14, margin: 0, width: 225}}>
                      {input.title}
                    </p>
                    <p style={{fontSize: 14, margin: 0}}>
                      ${separator(parseInt(input.value))}
                    </p>
                  </div>
                )
              }
            })}

            <div style={{width: 600, marginLeft: 100, display: 'flex'}}>
              <p style={{fontSize: 15, margin: 0, width: 90, fontWeight: 'bold'}}>
                Total Fringe
              </p>
              <p style={{fontSize: 15, margin: 0, width: 360}}>
                . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
              </p>
              <p style={{fontSize: 15, margin: 0, fontWeight: 'bold'}}>
                +${separator(calculateTotalFringe())}
              </p>
            </div>

            <div style={{width: 600, marginLeft: 75, display: 'flex', marginTop: 20}}>
              <p style={{fontSize: 15, margin: 0, width: 115}}>
                Interest Expense
              </p>
              <p style={{fontSize: 15, margin: 0, width: 360}}>
                . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
              </p>
              <p style={{fontSize: 15, margin: 0, fontWeight: 'bold'}}>
                +${separator(parseInt(cashFlowValues.cashflowAnalysisInterestExpense))}
              </p>
            </div>

            <div style={{width: 600, marginLeft: 75, display: 'flex'}}>
              <p style={{fontSize: 15, marginBottom: 0, width: 180}}>
                Depreciation/Amortization
              </p>
              <p style={{fontSize: 15, marginBottom: 0, width: 295}}>
                . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
              </p>
              <p style={{fontSize: 15, margin: 0, fontWeight: 'bold'}}>
                +${separator(parseInt(cashFlowValues.cashflowAnalysisDeprecationAmortization))}
              </p>
            </div>

            <p style={{marginLeft: 75, fontSize: 15, marginBottom: 0, width: 200, marginTop: 20}}>
              Other
            </p>

            {cashflowAnalysisInputArray.map((input, key) => {
              if(input.type === "Other"){
                return(
                  <div key={key} style={{width: 500, marginLeft: 100, display: 'flex'}}>
                    <p style={{fontSize: 14, margin: 0, width: 225}}>
                      {input.title}
                    </p>
                    <p style={{fontSize: 14, margin: 0}}>
                      ${separator(parseInt(input.value))}
                    </p>
                  </div>
                )
              }
            })}

            <div style={{width: 600, marginLeft: 100, display: 'flex', marginTop: 5}}>
              <p style={{fontSize: 15, margin: 0, width: 85, fontWeight: 'bold'}}>
                Total Other
              </p>
              <p style={{fontSize: 15, margin: 0, width: 365}}>
                . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
              </p>
              <p style={{fontSize: 15, margin: 0, fontWeight: 'bold'}}>
                +${separator(calculateTotalOther())}
              </p>
            </div>

            <div style={{width: 600, marginLeft: 75, display: 'flex', marginTop: 10}}>
              <p style={{fontSize: 17, marginBottom: 0, width: 245, fontWeight: 'bold'}}>
                Total Owner Benefit/Cashflow
              </p>
              <p style={{fontSize: 15, margin: 0, width: 230}}>
                  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
              </p>
              <p style={{marginLeft: 20, fontSize: 17, margin: 0, fontWeight: 'bold'}}>
                =${separator(parseInt(cashFlowValues.cashflowAnalysisNetOperatingIncome) + parseInt(cashFlowValues.cashflowAnalysisOwnerSalary) + calculateTotalFringe() + calculateTotalOther() + parseInt(cashFlowValues.cashflowAnalysisInterestExpense) + parseInt(cashFlowValues.cashflowAnalysisDeprecationAmortization))}
              </p>
            </div>

            <div style={{width: 580, marginLeft: 50, display: 'flex', marginTop: 40}}>
              <div style={{borderTop: '1px solid black', width: 240}}>
                <p style={{fontSize: 13, marginBottom: 0, width: 240}}>
                  Owner Signature
                </p>
              </div>

              <div style={{borderTop: '1px solid black', width: 240, marginLeft: '5%'}}>
                <p style={{width: 240, fontSize: 13, marginBottom: 0}}>
                  Owner Printed Name
                </p>
              </div>

              <div style={{borderTop: '1px solid black', width: 100, marginLeft: '5%'}}>
                <p style={{width: 100, fontSize: 13, marginBottom: 0}}>
                  Date
                </p>
              </div>
            </div>

            <p style={{fontSize: 9, width: 650, marginLeft: 50, marginBottom: 0, marginTop: 10}}>
              CONFIDENTIALITY INFORMATION: This information is taken from sources provided by the 
              Owner and is not warranted or guaranteed by the Broker(s) and is subject to corrections 
              and/or changes by Owner.
            </p>

            <p style={{fontSize: 10, width: 100, marginLeft: 575}}>
              CBA Form 103019
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

