import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@mui/material';
import { Scrollbar } from '../components/scrollbar';
import { useNavigate, Link } from "react-router-dom";


export const SellerQuestionnairesTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    setDeleteQuestionnaireId,
    setOpen
  } = props;

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Seller Name
                </TableCell>
                <TableCell>
                  Business Name
                </TableCell>
                <TableCell>
                  Address
                </TableCell>
                <TableCell>
                  Date Created
                </TableCell>
                <TableCell>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((questionnaire) => {
                return (
                  <TableRow style={{textDecoration: 'none', color: 'black'}} hover key={questionnaire.id}>
                    <TableCell component={Link} to={`/questionnaires/${questionnaire.id}`} state={{ id: questionnaire.id }} >
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <Typography variant="subtitle2">
                          {questionnaire.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {questionnaire.businessName}
                    </TableCell>
                    <TableCell>
                      {questionnaire.address}
                    </TableCell>
                    <TableCell>
                      {questionnaire.dateCreated}
                    </TableCell>
                    <TableCell>
                      <Button onClick={(event) => {
                          event.stopPropagation();
                          setDeleteQuestionnaireId(questionnaire.id)
                          setOpen(true)
                        }} 
                        sx={{backgroundColor: 'red', color: 'white', '&:hover': {backgroundColor: 'red'}}}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

SellerQuestionnairesTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array
};
