import BuildingOfficeIcon from '@heroicons/react/24/solid/BuildingOfficeIcon';
import Cog8ToothIcon from '@heroicons/react/24/solid/Cog8ToothIcon';
import DocumentDuplicateIcon from '@heroicons/react/24/solid/DocumentDuplicateIcon';
import UserPlusIcon from '@heroicons/react/24/solid/UserPlusIcon';
import DocumentTextIcon from '@heroicons/react/24/solid/DocumentTextIcon';

import { SvgIcon } from '@mui/material';

export const items = [
  {
    title: 'Contracts',
    path: '/contracts',
    icon: (
      <SvgIcon fontSize="small">
        <DocumentTextIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: (
      <SvgIcon fontSize="small">
        <Cog8ToothIcon />
      </SvgIcon>
    )
  },
];
