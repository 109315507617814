import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  Button,
  Typography,
  Stack,
  Container,
  List, 
  ListItem, 
  ListItemText,
  Grid
} from '@mui/material';


import 'bootstrap/dist/css/bootstrap.min.css';
import './Contracts.css'

const contractButton = {
  backgroundColor: '#45aa42',
  color: 'white',
  borderRadius: '5px'
}


export default function Contacts() {

  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>
          Create Contracts
        </title>
      </Helmet>
      <Box component="main" sx={{flexGrow: 1}}>
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
                Create Contracts
              </Typography>
            </div>
              <Typography variant="h5" gutterBottom>
                Benefits:
              </Typography>
              <List sx={{listStyleType: 'disc', pl: 4}}>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary="Quickly and easily create documents" />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText primary="All documents retain same formatting, maintaining consistancy" />
                </ListItem>
              </List>
              <Typography variant="h6" gutterBottom>
                Get started by clicking the buttons below:
              </Typography>

              <Grid container spacing={4} pr={5}>
                <Grid item xs={3}>
                  <Button fullWidth sx={contractButton} onClick={() => navigate("/contracts/consent-of-spouse", { replace: true })}>
                    Consent of Spouse
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button fullWidth sx={contractButton} onClick={() => navigate("/contracts/corporate-resolution-to-sell", { replace: true })}>
                    Corporate Resolution To Sell
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button fullWidth sx={contractButton} onClick={() => navigate("/contracts/broker-confidentiality", { replace: true })}>
                    Broker Confidentiality
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button fullWidth sx={contractButton} onClick={() => navigate("/contracts/cash-flow-analysis", { replace: true })}>
                    Cashflow Analysis
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Container>
      </Box>
    </>
  )
}

