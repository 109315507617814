import { useCallback, useMemo, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useSelection } from '../hooks/use-selection';
import { DocumentsTable } from './Documents-table';
import { applyPagination } from '../utils/apply-pagination';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';

import { 
  Box, 
  Container, 
  Stack, 
  Typography, 
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Divider,
  Avatar,
  SvgIcon,
  LinearProgress,
  Checkbox,
  OutlinedInput,
  InputAdornment
} from '@mui/material';

import ListBulletIcon from '@heroicons/react/24/solid/ListBulletIcon';

import { useNavigate, useLocation } from "react-router-dom";

import {
  doc,
  getDoc,
  getDocs,
  where,
  collection,
  query
} from 'firebase/firestore'
import {db} from '../Firebase/Firebase';

export default function Business(){

  const navigate = useNavigate()
  const { state } = useLocation();

  const [searchWord, setSearchWord] = useState('');
  const [documents, setDocuments] = useState([]);
  const [allDocuments, setAllDocuments] = useState([]);

  const [sellerValues, setSellerValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [businessValues, setBusinessValues] = useState({
    businessName: '',
    businessAddress: '',
    businessDescription: '',
    numOfEmployees: '',
    totalCustomers: '',
    askingPrice: '',
    cashflow: '',
    revenue: '',
    FFandE: '',
    yearsInBusiness: '',
    inventoryValue: '',
    competition: '',
    supportAndTraining: '',
    reasonForSelling: '',
    website: '',
    leaseCost: '',
    corporationType: 'LLC',
    carryInventory: '',
    buildingValue: ''
  });

  const [isLeaseAssignable, setIsLeaseAssignable] = useState('No')
  const [ownOrLeaseBuilding, setOwnOrLeaseBuilding] = useState('Lease')
  const [taskProgressPercent, setTaskProgressPercent] = useState(0)
  const [profitAndLossChecked, setProfitAndLossChecked] = useState(false)
  const [bankStatementChecked, setBankStatementChecked] = useState(false)
  const [balanceSheetChecked, setBalanceSheetChecked] = useState(false)
  const [assetListChecked, setAssetListChecked] = useState(false)
  const [taxReturnChecked, setTaxReturnChecked] = useState(false)
  const [payrollDocChecked, setPayrollDocChecked] = useState(false)
  const [customerListChecked, setCustomerListChecked] = useState(false)

  const handleChange = useCallback(
    (event) => {
      setBusinessValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    },
    []
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
    },
    []
  );

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const useDocuments = (page, rowsPerPage) => {
    return useMemo(
      () => {
        return applyPagination(documents, page, rowsPerPage);
      },
      [page, rowsPerPage]
    );
  };
  
  const useDocumentIds = (documents) => {
    return useMemo(
      () => {
        return documents.map((document) => document.id);
      },
      [documents]
    );
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const customers = useDocuments(page, rowsPerPage);
  const customersIds = useDocumentIds(customers);


  useEffect(() => {
    
    const getBusinessInfo = async () => { 

      let sellerId = ''
      const docRef = doc(db, "businesses", state.id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        sellerId = docSnap.data().sellerId
        setBusinessValues({
          businessName: docSnap.data().businessName,
          businessAddress: docSnap.data().address,
          businessDescription: docSnap.data().businessDescription,
          numOfEmployees: docSnap.data().numberOfEmployees,
          totalCustomers: docSnap.data().totalCustomers,
          revenue: docSnap.data().totalSales,
          website: docSnap.data().website,
          yearsInBusiness: docSnap.data().yearsInBusiness,
          leaseCost: docSnap.data().leaseCost,
          inventoryValue: docSnap.data().inventoryValue,
          carryInventory: docSnap.data().carryInventory,
          corporationType: docSnap.data().corporationType,
          buildingValue: docSnap.data().buildingValue,
        })
        setIsLeaseAssignable(docSnap.data().isLeaseAssignable ? 'Yes' : 'No')
        setOwnOrLeaseBuilding(docSnap.data().ownOrLeaseBuilding)

      } else {
        alert("Error: unable to get business data")
      }

      const sellerDocRef = doc(db, "sellers", docSnap.data().sellerId);
      const sellerDocSnap = await getDoc(sellerDocRef);
      
      if (sellerDocSnap.exists()) {
        const taskProgressPercent = parseInt((sellerDocSnap.data().uploadedDocumentTypes.length/7) * 100)
        setTaskProgressPercent(taskProgressPercent)

        const uploadedDocumentTypes = sellerDocSnap.data().uploadedDocumentTypes
        if(uploadedDocumentTypes.includes("Profit and Loss")){
          setProfitAndLossChecked(true)
        }
        if(uploadedDocumentTypes.includes("Bank Statement")){
          setBankStatementChecked(true)
        }
        if(uploadedDocumentTypes.includes("Balance Sheet")){
          setBalanceSheetChecked(true)
        }
        if(uploadedDocumentTypes.includes("Asset List")){
          setAssetListChecked(true)
        }
        if(uploadedDocumentTypes.includes("Tax Return")){
          setTaxReturnChecked(true)
        }
        if(uploadedDocumentTypes.includes("Payroll Doc")){
          setPayrollDocChecked(true)
        }
        if(uploadedDocumentTypes.includes("Customer List")){
          setCustomerListChecked(true)
        }

        setSellerValues({
          firstName: sellerDocSnap.data().firstName,
          lastName: sellerDocSnap.data().lastName,
          email: sellerDocSnap.data().email,
          phone: sellerDocSnap.data().phone,
        });

      } else {
        alert("Error: unable to get business data")
      }

      //  Getting all documents
      const documents = []
      const documentQuery = query(collection(db, "documents"), where("uploaderId", "==", sellerId))
      const documentQuerySnapshot = await getDocs(documentQuery);
      documentQuerySnapshot.forEach((doc) => {
        const document = {
          url: doc.data().url,
          fileExtension: doc.data().fileExtension,
          filename: doc.data().filename,
          uploadDate: doc.data().uploadDate,
          type: doc.data().type,
          year: doc.data().year,
          id: doc.id
        }
        documents.push(document)
      })
      setDocuments(documents)
      setAllDocuments(documents)

    }
    getBusinessInfo()
  },[]);

  function changeSearchword(event){
    setSearchWord(event.target.value)
    if(event.target.value.length > 0){
        const tempDocuments = []
        for(let i=0; i<allDocuments.length; i++){
            if(allDocuments[i].filename.toLowerCase().includes(event.target.value.toLowerCase()) ||
            allDocuments[i].type.toLowerCase().includes(event.target.value.toLowerCase()) ||
            allDocuments[i].uploadDate.toLowerCase().includes(event.target.value.toLowerCase()) ||
            allDocuments[i].url.toLowerCase().includes(event.target.value.toLowerCase())
            ){
              tempDocuments.push(allDocuments[i])
            }
        }
        setDocuments(tempDocuments)
    }
    else{
      setDocuments(allDocuments)
    }
  }


  return(
    <>
      <Helmet>
        <title>
          {businessValues.businessName} | The CBA Group
        </title>
      </Helmet>
      <Box component="main" sx={{flexGrow: 1}}>
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">
                  {businessValues.businessName}
                </Typography>
              </Stack>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="contained">
                  Save details
                </Button>
              </CardActions>
            </Stack>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12} md={6} lg={8}>
                  <Stack spacing={2}>
                    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Card>
                        <CardHeader subheader="The information can be edited" title="Business Info"/>
                        <CardContent sx={{ pt: 0 }}>
                          <Box sx={{ m: -1.5 }}>
                            <Grid container spacing={3} >
                              <Grid xs={12}>
                                <TextField
                                  fullWidth
                                  label="Address"
                                  name="businessAddress"
                                  onChange={handleChange}
                                  required
                                  value={businessValues.businessAddress}
                                />
                              </Grid>
                              <Grid xs={12}>
                                <TextField
                                  fullWidth
                                  label="Description"
                                  name="businessDescription"
                                  onChange={handleChange}
                                  required
                                  value={businessValues.businessDescription}
                                  rows={5}
                                  multiline
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                        <Divider />
                      </Card>
                    </form>
                    <Card>
                      <CardHeader title="Financial Info"/>
                      <CardContent sx={{ pt: 0 }}>
                        <Stack spacing={3}>
                          <TextField
                              fullWidth
                              label="Asking Price"
                              name="askingPrice"
                              onChange={handleChange}
                              required
                              value={businessValues.askingPrice}
                          />
                          <TextField
                            fullWidth
                            label="Cash Flow"
                            name="cashflow"
                            onChange={handleChange}
                            required
                            value={businessValues.cashflow}
                          />
                          <TextField
                            fullWidth
                            label="Value of Inventory"
                            name="inventoryValue"
                            onChange={handleChange}
                            required
                            value={businessValues.inventoryValue}
                          />
                        </Stack>
                      </CardContent>
                    </Card>
                    <Card>
                      <CardHeader title="Building Info"/>
                      <CardContent sx={{ pt: 0 }}>
                        <Stack spacing={3}>
                          <FormControl>
                            <FormLabel>Do you own or lease the property?</FormLabel>
                            <RadioGroup value={ownOrLeaseBuilding} onChange={(event) => setOwnOrLeaseBuilding(event.target.value)}>
                              <FormControlLabel value="Own" control={<Radio />} label="Own" />
                              <FormControlLabel value="Lease" control={<Radio />} label="Lease" />
                            </RadioGroup>
                          </FormControl>
                          {ownOrLeaseBuilding === 'Lease' && (
                            <FormControl>
                              <FormLabel>Is the lease assignable?</FormLabel>
                              <RadioGroup value={isLeaseAssignable} onChange={(event) => setIsLeaseAssignable(event.target.value)}>
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                              </RadioGroup>
                            </FormControl>
                          )}
                          <TextField
                            fullWidth
                            label="Cost of Monthly Lease/Rent"
                            name="leaseCost"
                            onChange={handleChange}
                            required
                            value={businessValues.leaseCost}
                          />
                          {ownOrLeaseBuilding === 'Own' && (
                            <TextField
                              fullWidth
                              label="Value of Building"
                              name="buildingValue"
                              onChange={handleChange}
                              required
                              value={businessValues.buildingValue}
                            />
                          )}
                       
                        </Stack>
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  <Stack spacing={3}>
                    <Card>
                      <CardContent>
                        <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                          <Typography gutterBottom variant="h5">
                            {sellerValues.firstName} {sellerValues.lastName}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {sellerValues.email}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {sellerValues.phone}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>

                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                          <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                          <Stack spacing={1}>
                              <Typography color="text.secondary" gutterBottom variant="overline">
                                Document Task Progress
                              </Typography>
                              <Typography variant="h4">
                                {taskProgressPercent}%
                              </Typography>
                          </Stack>
                          <Avatar sx={{backgroundColor: 'warning.main', height: 56, width: 56}}>
                              <SvgIcon>
                              <ListBulletIcon />
                              </SvgIcon>
                          </Avatar>
                          </Stack>
                          <Box sx={{ mt: 3 }}>
                            <LinearProgress value={taskProgressPercent} variant="determinate"/>
                          </Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={profitAndLossChecked}/>} label="1. Profit and Loss" />
                            <FormControlLabel control={<Checkbox checked={balanceSheetChecked}/>} label="2. Balance Sheet" />
                            <FormControlLabel control={<Checkbox checked={taxReturnChecked}/>} label="3. Tax Return" />
                            <FormControlLabel control={<Checkbox checked={bankStatementChecked}/>} label="4. Bank Statement" />
                            <FormControlLabel control={<Checkbox checked={assetListChecked}/>} label="5. Asset List" />
                            <FormControlLabel control={<Checkbox checked={payrollDocChecked}/>} label="6. Payroll Doc" />
                            <FormControlLabel control={<Checkbox checked={customerListChecked}/>} label="7. Customer List" />
                          </FormGroup>
                      </CardContent>
                    </Card>

                    <Card>
                      <CardHeader title="Other Info"/>
                      <CardContent sx={{ pt: 0 }}>
                        <Stack spacing={3}>
                          <TextField
                            fullWidth
                            label="Website"
                            name="website"
                            onChange={handleChange}
                            required
                            value={businessValues.website}
                          />
                          <TextField
                            fullWidth
                            label="Num. of Employees"
                            name="numOfEmployees"
                            onChange={handleChange}
                            required
                            value={businessValues.numOfEmployees}
                          />
                          <TextField
                            fullWidth
                            label="Total Customers"
                            name="totalCustomers"
                            onChange={handleChange}
                            required
                            value={businessValues.totalCustomers}
                          />
                          <TextField
                            fullWidth
                            label="Years in Business"
                            name="yearsInBusiness"
                            onChange={handleChange}
                            required
                            value={businessValues.yearsInBusiness}
                          />
                        </Stack>
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>

      <Box component="main" sx={{flexGrow: 1}}>
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Typography sx={{marginTop: 4}} variant="h4">
              Documents
            </Typography>
            
            <Stack spacing={3}>
              <Card sx={{ p: 2 }}>
                <OutlinedInput sx={{height: '40px'}} fullWidth placeholder="Search documents"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon
                        color="action"
                        fontSize="small"
                      >
                        <MagnifyingGlassIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                  onChange={(event) => changeSearchword(event)}
                  value={searchWord}
                />
                
              </Card>
              <DocumentsTable
                count={documents.length}
                items={documents}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

